const store = {
  id: 'linen-house',
  name: 'Linen House',
  model:
    'https://fidelitystorageprod-cdn.azureedge.net/cottonon-vr-store-3d/linen-house/store-models/linen-house-D.glb',
  modelId: '34a179b44680458f8aa571a9a274634c',
  storeFrontId: '97acc5ddd2b14ecabf3b255d5274c25c',
  storeLogo:
    'https://fidelitystorageprod-cdn.azureedge.net/cottonon-vr-store-3d/linen-house/linen-house-logo.svg',
  storeWelcomeText: 'Welcome to the Linen House Experience.',
  storeFavIcon:
    'https://fidelitystorageprod-cdn.azureedge.net/cottonon-vr-store-3d/linen-house/linenhouse-fav-icon.ico',
  cameraRotation: {
    azimuthal: -180,
    polar: 90,
  },
  rotationDegrees: 61,
  menus: [
    {
      title: 'Entrance',
      locationId: '97acc5ddd2b14ecabf3b255d5274c25c',
      options: {
        rotation: {
          azimuthal: -3.141592653589793,
          polar: 1.5707963267948966,
        },
      },
    },
  ],
  panoImageBaseUrl:
    'https://fidelitystorageprod-cdn.azureedge.net/cottonon-vr-store-3d/linen-house/pano-imgs-webp-512/',
  panoImageFormat: '.webp',
  tiledImageSettings: {
    baseUrl512:
      'https://fidelitystorageprod-cdn.azureedge.net/cottonon-vr-store-3d/linen-house/ocation-images-cube-512/',
    baseUrl2k:
      'https://fidelitystorageprod-cdn.azureedge.net/cottonon-vr-store-3d/linen-house/location-images-tiled-2k/',
    baseUrl4k:
      'https://fidelitystorageprod-cdn.azureedge.net/cottonon-vr-store-3d/linen-house/location-images-tiled-4k/',
  },
  layoutSettings: {
    modalAlignment: 'center',
  },
  adjustPanoramaVerticalAlignment: false,
};

export default store;
