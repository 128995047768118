import React, { useRef } from 'react';

import { Slider, makeStyles } from '@material-ui/core';
import VolumeOffOutlinedIcon from '@material-ui/icons/VolumeOffOutlined';
import VolumeUpOutlinedIcon from '@material-ui/icons/VolumeUpOutlined';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '0px 4px',
    background: 'rgba(255, 255, 255, 0.7)',
    borderRadius: 100,
    display: 'flex',
    alignItems: 'center',
    height: 32,
    padding: '0px 16px',
  },
  iconVolume: {
    cursor: 'pointer',
    padding: 0,
    border: 'none',
    color: '#000',
    width: 16,
    height: 16,
  },
  slider: {
    color: '#000',
    width: 60,
    marginLeft: 8,
  },
  thumb: {
    width: 8,
    height: 8,
    marginTop: -3,
    boxShadow:
      'none !important; -webkit-box-shadow: none !important; -moz-box-shadow: none !important;',
    '&:after': {
      display: 'none',
    },
  },
  rail: {
    opacity: 1,
  },
}));

export const VolumeSlider = (props) => {
  const { volume, handleVolumeChange } = props;
  const ref = useRef(null);
  const volumeRef = useRef(0.2);
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    handleVolumeChange(newValue);
  };

  const handleClick = () => {
    if (volume > 0) volumeRef.current = volume;
    handleVolumeChange(volume > 0 ? 0 : volumeRef.current);
  };

  return (
    <div className={classes.root} ref={ref}>
      {volume > 0 ? (
        <VolumeUpOutlinedIcon classes={{ root: classes.iconVolume }} onClick={handleClick} />
      ) : (
        <VolumeOffOutlinedIcon classes={{ root: classes.iconVolume }} onClick={handleClick} />
      )}
      <Slider
        value={volume}
        onChange={handleChange}
        aria-labelledby="volume-slider"
        min={0}
        step={0.1}
        max={1}
        scale={(x) => x - 0.1}
        classes={{ root: classes.slider, thumb: classes.thumb, rail: classes.rail }}
      />
    </div>
  );
};
