import pointerManager from '@utils/pointer.manager';
import React, { useRef } from 'react';

import { GroundPlane } from './GroundPlane';
import { SpaceModel } from './SpaceModel';
import { SurfacePointer } from './SurfacePointer';

export const Space = (props) => {
  const { url, handleRightMouseClickWorldLocation } = props;
  const mouseEventNames = { Down: 'down', Move: 'move', Click: 'click' };
  const lastMouseEventRef = useRef();

  const handlePointerUp = (e) => {
    e.stopPropagation();
    if (e.which === 3) {
      if (handleRightMouseClickWorldLocation) {
        handleRightMouseClickWorldLocation(e.point, e.face.normal);
      }
    }
  };

  const handlePointerDown = (e) => {
    e.stopPropagation();
    lastMouseEventRef.current = mouseEventNames.Down;
  };

  const handlePointerMove = (e) => {
    e.stopPropagation();
    pointerManager.updatePosition(e.point, e.face.normal);
    lastMouseEventRef.current = mouseEventNames.Move;
  };

  const handleClick = (e) => {
    e.stopPropagation();
    if (lastMouseEventRef.current != mouseEventNames.Move) {
      pointerManager.clickWithoutMove(e.point, e.face.normal);
    }
    lastMouseEventRef.current = mouseEventNames.Click;
  };

  return (
    <>
      <group position={[0, 0, 0]}>
        <GroundPlane position={[0, 0, 0]} rotation={[-Math.PI / 2, 0, 0]} />
        <SpaceModel
          url={url}
          position={[0, 0, 0]}
          onPointerUp={handlePointerUp}
          onPointerDown={handlePointerDown}
          onPointerMove={handlePointerMove}
          onClick={handleClick}
        />
        <SurfacePointer />
      </group>
    </>
  );
};
