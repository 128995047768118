import productService from '@apis/services/product.service';
import useModal from '@features/layout/modal/hooks/useModal';
import React, { useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { Box, Button, Divider, Grid, IconButton, Typography, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    height: 'calc(100% - 96px)',
  },
  scrollWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingRight: 16,
    marginRight: -16,
    maxHeight: '100%',
    height: '100%',
  },
  closeIconButton: {
    background: 'transparent',
    padding: 0,
    border: 'none',
    color: '#000',
    '& .MuiSvgIcon-root': {
      width: 24,
      height: 24,
    },
    '&:hover': {
      background: 'transparent',
    },
  },
  textRow: {
    fontWeight: '300',
  },
  title: {
    fontWeight: 500,
    fontSize: '1.6rem',
  },
  price: {
    fontWeight: 400,
  },
  infoTableName: {
    textTransform: 'uppercase',
  },
  infoTableHeaders: {
    textTransform: 'uppercase',
    fontWeight: 600,
  },
  buttonRoot: {
    borderRadius: 0,
    background: '#000',
    color: '#fff',
    padding: '8px 16px 8px 24px',
    marginTop: 8,
    width: 240,
    '& .MuiButton-label': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      lineHeight: 1,
      transition: `width 0.5s`,
      width: '85%',
      fontWeight: '300',
    },
    '& .MuiButton-endIcon': {
      margin: 0,
    },
    '&:hover': {
      background: '#000',
      color: '#fff',
      '& .MuiButton-label': {
        width: '100%',
      },
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  infoTableRow: {
    borderBottom: '1px solid #eee',
  },
}));

const ProductPreviewModalBody = (props) => {
  const { productSku } = props;
  const classes = useStyles();
  const [product, setProduct] = useState();
  const { close } = useModal();

  useEffect(() => {
    if (productSku) {
      console.log('productSku', productSku);
      const productList = productService.getProducts();
      const product = productList.find((x) => x.sku == productSku);
      console.log('====================================');
      console.log(product);
      console.log('====================================');
      setProduct(product);
    }
  }, [productSku]);

  return (
    <>
      {product && (
        <Grid className={classes.contentContainer}>
          <Grid item container alignItems="center">
            <Grid item xs>
              <Typography variant="h5" component="span" className={classes.title}>
                {product.title}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton disableRipple className={classes.closeIconButton} onClick={close}>
                <CloseIcon />
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" className={classes.price}>{`$${product.price}`}</Typography>
            </Grid>
          </Grid>
          <Grid item xs>
            <Box mb={2} mt={1}>
              <Divider />
            </Box>
          </Grid>

          <PerfectScrollbar className={classes.scrollWrapper} options={{ suppressScrollX: true }}>
            <Grid
              container
              style={{
                height: '100%',
              }}
              spacing={2}
              alignContent="flex-start"
              alignItems="baseline"
            >
              <Grid item xs={12}>
                <Box
                  sx={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <img src={product.imagePreview} alt="product" width={240} />
                </Box>
              </Grid>
              <Grid item container xs={12} spacing={3}>
                {product.infoTables &&
                  product.infoTables.map((item) => {
                    return (
                      <Grid item container key={item.name} spacing={2}>
                        <Grid item xs={12}>
                          <Typography variant="h6" className={classes.infoTableName}>
                            {item.name}
                          </Typography>
                        </Grid>
                        {item.headers?.length > 0 && (
                          <Grid item container>
                            <Grid item xs>
                              <Typography variant="body2" className={classes.infoTableHeaders}>
                                {item.headers[0]}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography variant="body2" className={classes.infoTableHeaders}>
                                {item.headers[1]}
                              </Typography>
                            </Grid>
                          </Grid>
                        )}
                        {item.rows &&
                          item.rows.map((item, index) => {
                            return (
                              <Grid item container key={index} className={classes.infoTableRow}>
                                <Grid item xs>
                                  <Typography variant="body2" component="span">
                                    {item[0]}
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Typography variant="body2" component="span">
                                    {item[1]}
                                  </Typography>
                                </Grid>
                              </Grid>
                            );
                          })}
                      </Grid>
                    );
                  })}
              </Grid>
              <Grid item xs className={classes.buttonContainer}>
                <Button
                  variant="contained"
                  classes={{ root: classes.buttonRoot }}
                  endIcon={<NavigateNextIcon />}
                  onClick={() => {
                    window.open(product.url, '_blank', 'noreferrer');
                  }}
                >
                  View More Details
                </Button>
              </Grid>
            </Grid>
          </PerfectScrollbar>
        </Grid>
      )}
    </>
  );
};

export default ProductPreviewModalBody;
