import productService from '@apis/services/product.service';
import tagGroupsService from '@apis/services/tag-groups.service';
import tagService from '@apis/services/tag.service';
import useTagsStatus from '@features/3d-Space/tags/hooks/useTagsStatus';
import useModal from '@features/layout/modal/hooks/useModal';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { CircularProgress, Grid, makeStyles } from '@material-ui/core';

import { CompleteLookButton } from './CompleteLookButton';
import { CompleteLookContainer } from './CompleteLookContainer';
import { ProductDetails } from './ProductDetails';

const useStyles = makeStyles((theme) => ({
  closeIconButton: {
    background: 'transparent',
    padding: 0,
    border: 'none',
    color: '#000',
    '& .MuiSvgIcon-root': {
      width: 26,
      height: 26,
    },
    '&:hover': {
      background: 'transparent',
    },
  },
  productSeries: {
    fontSize: 24,
    fontWeight: 700,
    marginRight: 12,
  },
  productName: {
    fontSize: 24,
  },
  sizeGuideRow: {
    fontSize: 20,
    color: '#000',
    '& div': {
      margin: '8px 0',
    },
    '& div:last-child': {
      textAlign: 'end',
    },
  },
  fontBold: {
    fontWeight: 700,
  },
  fontFade: {
    color: '#0009',
  },
  contentContainer: {
    maxHeight: '100%',
    // height: '100%',
    overflowX: 'hidden',
    overflowY: 'hidden',
  },
  spinner: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export const ProductModalBody = (props) => {
  const classes = useStyles();
  const completeLookRef = useRef();
  const detailRef = useRef();

  const [tagGroup, setTagGroup] = useState();
  const [product, setProduct] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const { isOpen } = useModal();
  const { currentTagId, setCurrentActiveTag, setCurrentActiveTags } = useTagsStatus();

  const tagData = useMemo(() => tagService.getTagById(currentTagId), [currentTagId]);

  useEffect(() => {
    if (tagData) {
      let tagGroup = tagGroupsService.getTagGroupByTagId(tagData.id);
      setTagGroup(tagGroup ? tagGroup : null);
      setIsScrollView(false);
    } else {
      setTagGroup(null);
    }
  }, [tagData]);

  const [isScrollView, setIsScrollView] = useState(false);

  const handleScrollView = () => {
    setIsScrollView((prev) => !prev);
  };

  const scrollViewUp = () => {
    detailRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    setCurrentActiveTag(tagData.id);
  };

  const scrollViewDown = () => {
    completeLookRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    setCurrentActiveTags(tagGroup.tags);
  };

  useEffect(() => {
    if (tagGroup && completeLookRef?.current && detailRef?.current) {
      isScrollView ? scrollViewDown() : scrollViewUp();
    }
  }, [isScrollView]);

  useEffect(() => {
    return () => setIsScrollView(false);
  }, [isOpen]);

  useEffect(() => {
    const getProductByProductId = async (id) => {
      let product = await productService.getProductById(id);
      setProduct(product);
    };
    if (isOpen && tagData && tagData.productId) {
      setIsLoading(true);
      getProductByProductId(tagData.productId);
    }
  }, [isOpen, tagData]);

  useEffect(() => {
    if (product) {
      setIsLoading(false);
    }
  }, [product]);

  return (
    <>
      <Grid container className={classes.contentContainer}>
        <Grid item container style={{ height: '100%' }} ref={detailRef}>
          {product && !isLoading ? (
            <ProductDetails product={product} />
          ) : (
            <div className={classes.spinner}>
              <CircularProgress />
            </div>
          )}
        </Grid>
        <Grid item container style={{ height: '100%' }} ref={completeLookRef}>
          <CompleteLookContainer tagId={tagData?.id} handleScrollView={handleScrollView} />
        </Grid>
      </Grid>
      {tagGroup ? (
        <CompleteLookButton active={isScrollView} onClick={handleScrollView} />
      ) : (
        <span style={{ paddingTop: 40 }}></span>
      )}
    </>
  );
};
