import useLocations from '@features/3d-Space/locations/hooks/useLocations';
import locationService from '@features/3d-Space/locations/services/location.service';
import navigationManager from '@utils/navigation.manager';
import React, { useEffect, useMemo, useRef } from 'react';
import { Vector3 } from 'three';

import { useFrame } from '@react-three/fiber';

import { useKeyboardControls } from './useKeyboardControls';

export const KeyboardControls = (props) => {
  const { currentPosition, currentPointId } = props;
  const groupRef = useRef();
  const cameraRef = useRef();
  const newPoint = useMemo(() => new Vector3(), []);

  let direction = useMemo(() => new Vector3());
  let frontVector = useMemo(() => new Vector3());
  let sideVector = useMemo(() => new Vector3());
  const { locationList } = useLocations();

  const { moveForward, moveBackward, moveLeft, moveRight, ...rotateKey } = useKeyboardControls();

  const handleKeyPress = (point) => {
    // console.log('point', point, groupRef.current.position, currentPosition);
    let nearestLocation = locationService.getNearestLocation(point, locationList, currentPointId);
    // console.log('nearestLocation', nearestLocation);
    navigationManager.requestNewLocation(nearestLocation, true);
  };

  useEffect(() => {
    if (moveForward || moveBackward || moveLeft || moveRight) {
      frontVector.set(0, 0, (moveBackward ? 2.5 : 0) - (moveForward ? 2.5 : 0));
      sideVector.set((moveLeft ? 2.5 : 0) - (moveRight ? 2.5 : 0), 0, 0);

      direction.subVectors(frontVector, sideVector).applyEuler(cameraRef.current);
      newPoint.copy(groupRef.current.position.clone().add(direction));

      handleKeyPress(newPoint);
    }
  }, [moveForward, moveBackward, moveLeft, moveRight]);

  useFrame(({ camera }) => {
    cameraRef.current = camera.rotation;
  });

  return <group ref={groupRef} position={[currentPosition.x, -0.5, currentPosition.z]} />;
};
