import { CameraContext } from '@contexts/CameraContext';
import { CurrentPositionContext } from '@contexts/CurrentPositionContext';
import { TransitionContext } from '@contexts/TransitionContext';
import { LocationsContextProvider } from '@features/3d-Space/locations/hooks/useLocations';
import { TagProvider } from '@features/3d-Space/tags';
import { ModalProvider } from '@features/layout/modal';
import { XYZControlProvider } from '@features/layout/xyz-control/useXYZControl';
import { BackgroundMusicProvider } from '@hooks/useBackgroundMusic';
import { CameraTargetProvider } from '@hooks/useCameraTarget';
import { SpaceContextProvider } from '@hooks/useSpaceContext';
import React, { useState } from 'react';

export const AppProvider = ({ children }) => {
  const [transitionContext, setTransitionContext] = useState();
  const [cameraContext, setCameraContext] = useState();
  const [currentPositionContext, setCurrentPositionContext] = useState();
  return (
    <SpaceContextProvider>
      <LocationsContextProvider>
        <BackgroundMusicProvider>
          <ModalProvider>
            <CurrentPositionContext.Provider
              value={[currentPositionContext, setCurrentPositionContext]}
            >
              <TagProvider>
                <TransitionContext.Provider value={[transitionContext, setTransitionContext]}>
                  <CameraContext.Provider value={[cameraContext, setCameraContext]}>
                    <CameraTargetProvider>
                      <XYZControlProvider>{children}</XYZControlProvider>
                    </CameraTargetProvider>
                  </CameraContext.Provider>
                </TransitionContext.Provider>
              </TagProvider>
            </CurrentPositionContext.Provider>
          </ModalProvider>
        </BackgroundMusicProvider>
      </LocationsContextProvider>
    </SpaceContextProvider>
  );
};
