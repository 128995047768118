import tagsRepository from '@apis/repositories/tags.repository';

class TagService {
  constructor() {}

  getTags = () => {
    return tagsRepository.getTags();
  };

  getTagById = (tagId) => {
    return tagsRepository.getTagById(tagId);
  };
}

export default new TagService();
