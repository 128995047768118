import { myEmitter } from '../seed-works/myEmitter';

class PointerManager {
  constructor() {}

  updatePosition = (point, face) => {
    myEmitter.emit(SurfacePointerEventName.UpdatePosition, point, face);

    if (Math.abs(point.y) < 0.1 && Math.abs(face.y) > 0.95) {
      myEmitter.emit(SurfacePointerEventName.HoverFloorArea);
    } else {
      myEmitter.emit(SurfacePointerEventName.ChangeToDefault);
    }
  };

  hoverLocationPoint = () => {
    myEmitter.emit(SurfacePointerEventName.HoverLocationPoint);
  };

  changeToDefault = () => {
    myEmitter.emit(SurfacePointerEventName.ChangeToDefault);
  };

  clickWithoutMove = (point, face) => {
    if (Math.abs(point.y) < 0.1 && Math.abs(face.y) > 0.95) {
      myEmitter.emit(SurfacePointerEventName.FloorClicked, point);
    }
    myEmitter.emit(SurfacePointerEventName.ClickWithoutMove, point);
  };

  onPositionUpdate = (onPositionUpdate) => {
    if (onPositionUpdate) {
      myEmitter.on(SurfacePointerEventName.UpdatePosition, onPositionUpdate);
    }
  };
  offPositionUpdate = (onPositionUpdate) => {
    if (onPositionUpdate) {
      myEmitter.off(SurfacePointerEventName.UpdatePosition, onPositionUpdate);
    }
  };

  onHoverLocationPoint = (onHoverLocationPoint) => {
    if (onHoverLocationPoint) {
      myEmitter.on(SurfacePointerEventName.HoverLocationPoint, onHoverLocationPoint);
    }
  };
  offHoverLocationPoint = (onHoverLocationPoint) => {
    if (onHoverLocationPoint) {
      myEmitter.off(SurfacePointerEventName.HoverLocationPoint, onHoverLocationPoint);
    }
  };

  onHoverFloorArea = (onHoverFloorArea) => {
    if (onHoverFloorArea) {
      myEmitter.on(SurfacePointerEventName.HoverFloorArea, onHoverFloorArea);
    }
  };
  offHoverFloorArea = (onHoverFloorArea) => {
    if (onHoverFloorArea) {
      myEmitter.off(SurfacePointerEventName.HoverFloorArea, onHoverFloorArea);
    }
  };

  onChangeToDefault = (onChangeToDefault) => {
    if (onChangeToDefault) {
      myEmitter.on(SurfacePointerEventName.ChangeToDefault, onChangeToDefault);
    }
  };
  offChangeToDefault = (onChangeToDefault) => {
    if (onChangeToDefault) {
      myEmitter.off(SurfacePointerEventName.ChangeToDefault, onChangeToDefault);
    }
  };

  onFloorClicked = (onFloorClicked) => {
    if (onFloorClicked) {
      myEmitter.on(SurfacePointerEventName.FloorClicked, onFloorClicked);
    }
  };
  offFloorClicked = (onFloorClicked) => {
    if (onFloorClicked) {
      myEmitter.off(SurfacePointerEventName.FloorClicked, onFloorClicked);
    }
  };
  
  onClickWithoutMove = (onClickWithoutMove) => {
    if (onClickWithoutMove) {
      myEmitter.on(SurfacePointerEventName.ClickWithoutMove, onClickWithoutMove);
    }
  };
  offClickWithoutMove = (onClickWithoutMove) => {
    if (onClickWithoutMove) {
      myEmitter.off(SurfacePointerEventName.ClickWithoutMove, onClickWithoutMove);
    }
  };
}

const SurfacePointerEventName = {
  UpdatePosition: 'SPUpdatePosition',
  HoverLocationPoint: 'SPhoverLocationPoint',
  HoverFloorArea: 'SPHoverFloorArea',
  ChangeToDefault: 'SPChangeToDefault',
  FloorClicked: 'SPFloorClicked',
  ClickWithoutMove: 'SPClickWithoutMove'
};

export default new PointerManager();
