import { useEffect, useState } from 'react';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return width > height ? true : false;
}

export default function useWindowLandscape() {
  const [windowLandscape, setWindowLandscape] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowLandscape(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowLandscape;
}
