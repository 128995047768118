import tagService from '@apis/services/tag.service';
import { CurrentPositionContext } from '@contexts/CurrentPositionContext';
import useSpaceContext from '@hooks/useSpaceContext';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';

const TagContext = createContext({
  currentTagId: '',
  activeTagIds: [],
  focusTagIds: [],
  setCurrentActiveTag: (tagId) => {},
  clearActiveTag: () => {},
  setCurrentActiveTags: (tagIds) => {},
  checkIsInFocusArea: (tagIds) => {
    return false;
  },
});

export const TagProvider = ({ children }) => {
  const { spaceId, initializedDataStorage } = useSpaceContext();
  const tagsData = useMemo(() => {
    if (spaceId && initializedDataStorage) {
      return tagService.getTags();
    } else {
      return null;
    }
  }, [spaceId, initializedDataStorage]);
  const [activeTagIds, setActiveTagIds] = useState([]);
  const [focusTagIds, setFocusTagIds] = useState([]);
  const [currentTagId, setCurrentTagId] = useState();
  const [currentPositionContext] = useContext(CurrentPositionContext);
  const focusTagIdsRef = useRef();

  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    function checkTagPosition(currentPosition, tagPosition) {
      const n = 3;
      if (tagPosition < currentPosition + n && tagPosition > currentPosition - n) {
        return true;
      } else {
        return false;
      }
    }

    if (currentPositionContext && tagsData && tagsData.length) {
      let tmpId = [];
      tagsData.forEach((tag) => {
        let checkX = checkTagPosition(currentPositionContext.x, tag.position.x);
        let checkZ = checkTagPosition(currentPositionContext.z, tag.position.z);
        if (checkX && checkZ) {
          tmpId.push(tag.id);
        }
      });

      setFocusTagIds(tmpId);
      focusTagIdsRef.current = tmpId;
    }
  }, [currentPositionContext, tagsData]);

  const setCurrentActiveTag = useCallback((tagId) => {
    if (tagId) {
      let tmpIds = [];
      tmpIds.push(tagId);
      setActiveTagIds([...tmpIds]);
      setCurrentTagId(tagId);

      setFocusTagIds([...tmpIds]);
    }
  }, []);

  const setCurrentActiveTags = useCallback((tagIds) => {
    if (tagIds) {
      setActiveTagIds([...tagIds]);

      setFocusTagIds([...tagIds]);
    }
  }, []);

  const clearActiveTag = useCallback(() => {
    setActiveTagIds((prv) => []);
    setCurrentTagId('');
    setFocusTagIds(focusTagIdsRef.current);
  }, [focusTagIdsRef?.current]);

  const checkIsInFocusArea = (tagId) => {
    if (focusTagIdsRef.current && focusTagIdsRef.current.includes(tagId)) {
      return true;
    } else {
      return false;
    }
  };

  const setFirstLoadCurrentTag = useCallback((tagId) => {
    setCurrentTagId(tagId);
  }, []);

  useEffect(() => {
    if (tagsData) {
      const productSku = searchParams.get('sku');
      const firstTag = tagsData.find((x) => x.productSku === productSku);
      setFirstLoadCurrentTag(firstTag?.id || '');
    }
  }, [tagsData]);

  let contextValue = {
    currentTagId: currentTagId,
    activeTagIds: activeTagIds,
    focusTagIds: focusTagIds,
    setCurrentActiveTag,
    clearActiveTag,
    setCurrentActiveTags,
    checkIsInFocusArea,
  };

  return <TagContext.Provider value={contextValue}>{children}</TagContext.Provider>;
};

const useTagsStatus = () => {
  const context = useContext(TagContext);

  if (context === undefined) {
    throw new Error('useTagsStatus must be used within TagContext');
  }

  return context;
};

export default useTagsStatus;
