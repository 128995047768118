import React, { createContext, useCallback, useContext, useState } from 'react';

const CameraTargetContext = createContext({
  currentTarget: {},
  setCameraTargetPosition: (target) => {},
  clearCameraTarget: () => {},
});

export const CameraTargetProvider = ({ children }) => {
  const [currentTarget, setCurrentTarget] = useState({});

  const setCameraTargetPosition = useCallback(
    (target) => {
      if (target) {
        setCurrentTarget(target);
        // console.log('focusTagIdsRef.current', focusTagIdsRef.current);
      }
    },
    [currentTarget]
  );

  const clearCameraTarget = useCallback(() => {
    setCurrentTarget((prev) => {});
  }, [currentTarget]);

  let contextValue = {
    currentTarget: currentTarget,
    setCameraTargetPosition,
    clearCameraTarget,
  };

  return (
    <CameraTargetContext.Provider value={contextValue}>{children}</CameraTargetContext.Provider>
  );
};

const useCameraTarget = () => {
  const context = useContext(CameraTargetContext);

  if (context === undefined) {
    throw new Error('useTagsStatus must be used within TagContext');
  }

  return context;
};

export default useCameraTarget;
