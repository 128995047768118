import DataStorage from '@apis/data/DataStorage';

class StoresRepository {
  constructor() {
    this.store = {};
    this.refreshAt;
  }

  _convertToStoreData = (data) => {
    let store = {
      id: data.id,
      name: data.name,
      modelSource: data.model,
      storeFront: data.storeFrontId,
      logo: data.storeLogo,
      welcomeText: data.storeWelcomeText,
      favIcon: data.storeFavIcon || '',
      cameraRotation: data.cameraRotation,
      rotationDegrees: data.rotationDegrees,
      menus: data.menus,
      panoImageBaseUrl: data.panoImageBaseUrl,
      panoImageFormat: data.panoImageFormat,
      tiledImageSettings: data.tiledImageSettings,
      layoutSettings: data.layoutSettings,
      adjustPanoramaVerticalAlignment: data.adjustPanoramaVerticalAlignment
    };

    return store;
  };

  reloadStoreIfNeeded = () => {
    if (this.refreshAt != DataStorage.refreshAt) {
      // console.log('reloadStoreIfNeeded');
      this.store = this._convertToStoreData(DataStorage.store);
      this.refreshAt = DataStorage.refreshAt;
    }
  };

  getStore = () => {
    this.reloadStoreIfNeeded();
    return this.store;
  };
}

export default new StoresRepository();
