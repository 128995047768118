import DataStorage from '@apis/data/DataStorage';

class TagsRepository {
  constructor() {
    this.tags = [];
    this.refreshAt;
  }

  reloadTagsIfNeeded = () => {
    if (this.refreshAt != DataStorage.refreshAt) {
      // let tmpTags = DataStorage.tags;
      // let tmpProducts = DataStorage.products;
      // let tagResults = tmpTags?.filter(
      //   (tag) => tag.productId && tmpProducts?.some((product) => product.sku === tag.productId)
      // );
      // console.log('tagResults', tagResults);
      this.tags = DataStorage.tags;
      // this.tags = tagResults;
      this.refreshAt = DataStorage.refreshAt;
    }
  };

  getTags = () => {
    this.reloadTagsIfNeeded();
    return this.tags;
  };

  getTagById = (id) => {
    this.reloadTagsIfNeeded();
    return this.tags?.find((x) => x.id == id);
  };
}

export default new TagsRepository();
