import useLocations from '@features/3d-Space/locations/hooks/useLocations';
import locationService from '@features/3d-Space/locations/services/location.service';
import useCameraTarget from '@hooks/useCameraTarget';
import navigationManager from '@utils/navigation.manager';
import { useMemo } from 'react';
import { Vector3 } from 'three';

import useTagsStatus from './useTagsStatus';

export default function useTag() {
  const { setCurrentActiveTag, checkIsInFocusArea } = useTagsStatus();
  const { setCameraTargetPosition } = useCameraTarget();
  const { locationList } = useLocations();
  const vec3 = useMemo(() => new Vector3(), []);

  const handleTagClick = (tag, action) => {
    let inFocusArea = checkIsInFocusArea(tag.id);
    if (inFocusArea) {
      setCurrentActiveTag(tag.id);
      setCameraTargetPosition(tag.position);
      action();
    } else {
      const handleOnceRest = () => {
        setCurrentActiveTag(tag.id);
        setCameraTargetPosition(tag.position);
        action();
      };

      let position = vec3.set(tag.position.x, tag.position.y, tag.position.z);
      let nearestLocation = locationService.getNearestLocation(position, locationList);
      navigationManager.onceRest(handleOnceRest);
      navigationManager.requestNewLocation(nearestLocation, true);
    }
  };

  return { handleTagClick };
}
