const tags = [
  {
    id: '1',
    type: 'price',
    face: { x: 0.9804802661499954, y: 0.19659569686253708, z: 0.0029291066160109635 },
    position: { x: 30.684302717428146, y: 1.0260211814227767, z: 24.788550201345046 },
    discount: '',
    price: 69.99,
    productId: '2053326-23',
  },
  {
    id: '2',
    type: 'price',
    face: { x: 0.7903284203468183, y: 0.19381192079372558, z: -0.5812210658177744 },
    position: { x: 29.857905295669006, y: 1.647872664793385, z: 27.38412684904211 },
    discount: '',
    price: 79.99,
    productId: '2054868-02',
  },
  {
    id: '3',
    type: 'price',
    face: { x: 0.1383423332291831, y: 0.015339923927852453, z: -0.9902656641379591 },
    position: { x: 28.59548031412261, y: 0.9891924898425909, z: 31.869046240088554 },
    discount: '',
    price: 59.99,
    productId: '2054319-01',
  },
  {
    id: '4',
    type: 'price',
    face: { x: 0.21329194726600617, y: 0.9501291262799851, z: -0.22751085386395814 },
    position: { x: 26.05344403999023, y: 0.9014565576426978, z: 27.54933753754548 },
    discount: '',
    price: 59.99,
    productId: '2054850-03',
  },
  {
    id: '5',
    type: 'price',
    face: { x: 0.03082639033090309, y: 0.8857289594755986, z: 0.4631780910248674 },
    position: { x: 28.27736334318366, y: 0.9742937839383731, z: 23.892520185039416 },
    discount: '',
    price: 19.99,
    productId: '2054995-04',
  },
  {
    id: '6',
    type: 'price',
    face: { x: 0.7364366364723745, y: 0.13438685032083658, z: 0.6630243245327436 },
    position: { x: 23.581480445001795, y: 1.374012829979817, z: 26.875051095490225 },
    discount: '',
    price: 39.99,
    productId: '2051452-04',
  },
  {
    id: '7',
    type: 'price',
    face: { x: 0.9996234281725809, y: -0.027306543418728058, z: -0.002711924596675642 },
    position: { x: 23.99434008023854, y: 1.2845311893101024, z: 23.765763830019146 },
    discount: '',
    price: 89.99,
    productId: '2054906-02',
  },
  {
    id: '8',
    type: 'price',
    face: { x: -0.5834801690126563, y: 0.8101090009691313, z: -0.0572214899994568 },
    position: { x: 27.233626814629087, y: 0.9224822504053998, z: 19.8379136933928 },
    discount: '',
    price: 59.99,
    productId: '2001752-37',
  },
  {
    id: '9',
    type: 'price',
    face: { x: -0.16343192265712062, y: 0.9861124096258543, z: 0.029535101802564582 },
    position: { x: 23.91548963715735, y: 0.8782739456269836, z: 18.229007290428363 },
    discount: '',
    price: 59.99,
    productId: '2051567-19',
  },
  {
    id: '10',
    type: 'price',
    face: { x: -0.14229542691485939, y: 0.1105719593466094, z: 0.9836289205210315 },
    position: { x: 29.435950264750353, y: 2.277714683851591, z: 16.47088881170356 },
    discount: '',
    price: 44.99,
    productId: '2054888-01',
  },
  {
    id: '11',
    type: 'price',
    face: { x: 0.027831242494197838, y: 0.0757711976048054, z: 0.9967367493750607 },
    position: { x: 17.625756653760046, y: 1.8426965366084753, z: 16.286264303311224 },
    discount: '',
    price: 39.99,
    productId: '2052716-15',
  },
  {
    id: '12',
    type: 'price',
    face: { x: -0.04599881367807327, y: 0.05120166472456903, z: 0.997628437179716 },
    position: { x: 16.986234919606602, y: 1.6514522351026348, z: 16.309629085464035 },
    discount: '',
    price: 49.99,
    productId: '2055083-03',
  },
  {
    id: '13',
    type: 'price',
    face: { x: 0.8851728121127677, y: 0.3118000020297536, z: -0.34532571788185784 },
    position: { x: 12.35317461278687, y: 1.4958818316605071, z: 20.398214225320206 },
    discount: '',
    price: 34.99,
    productId: '2054468-07',
  },
  {
    id: '14',
    type: 'price',
    face: { x: -0.21614128315185518, y: 0.024582243140549596, z: -0.9760525903042563 },
    position: { x: 11.498291907907353, y: 1.2071106402500038, z: 21.823702891823512 },
    discount: '',
    price: 12.99,
    productId: '4589763-16',
  },
  {
    id: '15',
    type: 'price',
    face: { x: 0.042125302238725765, y: -0.04362762292190158, z: -0.9981593507198538 },
    position: { x: 11.867773502504667, y: 1.157711251076782, z: 25.334521766521647 },
    discount: '',
    price: 19.99,
    productId: '4590319-01',
  },
  {
    id: '16',
    type: 'price',
    face: { x: 0.04167847529286601, y: -0.04309055533809212, z: -0.998201436954444 },
    position: { x: 12.137809484081156, y: 0.9260093480047364, z: 25.355850439317283 },
    discount: '',
    price: 24.99,
    productId: '4590089-01',
  },
  {
    id: '17',
    type: 'price',
    face: { x: -0.0830160669616119, y: 0.21975508077342495, z: -0.9720164798502597 },
    position: { x: 10.354951516926196, y: 1.5752046769626133, z: 26.717291035150467 },
    discount: '',
    price: 24.99,
    productId: '4255639-07',
  },
  {
    id: '18',
    type: 'price',
    face: { x: -0.041913681370044524, y: 0.8818684388117921, z: 0.46962889598241636 },
    position: { x: 12.270940918307508, y: 1.2118595692088536, z: 27.609098085062445 },
    discount: '',
    price: 49.99,
    productId: '2055421-02',
  },
  {
    id: '19',
    type: 'price',
    face: { x: -0.07626189659423001, y: 0.01908902510144246, z: -0.9969050768496104 },
    position: { x: 16.359556728940184, y: 1.3556671738962156, z: 33.92623471451428 },
    discount: '',
    price: 29.99,
    productId: '2054185-28',
  },
  {
    id: '20',
    type: 'price',
    face: { x: 0.6648929183459978, y: 0.06525796150506745, z: -0.7440825260638402 },
    position: { x: 10.797071615945914, y: 1.0678966101470033, z: 29.196665099590046 },
    discount: '',
    price: 49.99,
    productId: '2055199-04',
  },
  {
    id: '21',
    type: 'price',
    face: { x: 0.9233222743312766, y: 0.37389410908761905, z: 0.087630890177464 },
    position: { x: 10.198968200303023, y: 1.4989753477739929, z: 31.237044524396538 },
    discount: '',
    price: 39.99,
    productId: '2054243-01',
  },
  {
    id: '22',
    type: 'price',
    face: { x: 0.7762306135171034, y: 0.41617029473909295, z: -0.47356976298707837 },
    position: { x: 11.472043753698712, y: 0.9118282832102437, z: 17.35685888955557 },
    discount: '',
    price: 19.99,
    productId: '2054848-08',
  },
  {
    id: '23',
    type: 'price',
    face: { x: 0.09765211546882809, y: 0.30450104112926724, z: -0.9474931030332912 },
    position: { x: 8.129578354793553, y: 1.6712768947859855, z: 19.243663909856206 },
    discount: '',
    price: 29.99,
    productId: '4590316-07',
  },
  {
    id: '24',
    type: 'price',
    face: { x: 0.08924665288616415, y: 0.18666552317787108, z: -0.9783613940693633 },
    position: { x: 9.31955817685837, y: 1.6408873311745247, z: 19.612111023756654 },
    discount: '',
    price: 6.99,
    productId: '422833-120',
  },
  {
    id: '25',
    type: 'price',
    face: { x: -0.11207733978644205, y: -0.12490056588292514, z: 0.9858187047061541 },
    position: { x: 7.024121013858378, y: 1.7041203032185, z: 16.48788251874787 },
    discount: '',
    price: 24.99,
    productId: '6333009-13',
  },
  {
    id: '26',
    type: 'price',
    face: { x: -0.20543796363845565, y: -0.058837950338668246, z: 0.9768998611403469 },
    position: { x: 7.135775831578984, y: 1.210873603104871, z: 16.495571725372184 },
    discount: '',
    price: 49.99,
    productId: '6333834-03',
  },
  {
    id: '27',
    type: 'price',
    face: { x: 0.5640410533748126, y: 0.06163705644374321, z: 0.823443114842053 },
    position: { x: 6.5473780674873945, y: 1.0578187421569258, z: 17.667644904098932 },
    discount: '',
    price: 49.99,
    productId: '6334440-04',
  },
  {
    id: '28',
    type: 'price',
    face: { x: 0.6586367822066398, y: 0.2617559838021592, z: 0.70546537411006 },
    position: { x: 6.778150013488996, y: 1.0054531723574582, z: 10.495042939394082 },
    discount: '',
    price: 34.99,
    productId: '6334193-10',
  },
  {
    id: '29',
    type: 'price',
    face: { x: -0.93762842518406, y: 0.18332311402479223, z: 0.29537361451408056 },
    position: { x: 13.74257707215267, y: 1.2815278948101425, z: 8.808135157849113 },
    discount: '',
    price: 24.99,
    productId: '4588909-24',
  },
  {
    id: '30',
    type: 'price',
    face: { x: -0.35264915947754977, y: 0.026081641385187385, z: 0.9353920666236336 },
    position: { x: 10.670374288661701, y: 2.6461058062503744, z: 2.610739377309919 },
    discount: '',
    price: 29.99,
    productId: '6334512-01',
  },
  {
    id: '31',
    type: 'price',
    face: { x: -0.12237023892895639, y: 0.0648704503783402, z: 0.9903622313538524 },
    position: { x: 9.609898817888936, y: 1.869312613092236, z: 2.634594984906829 },
    discount: '',
    price: 49.99,
    productId: '6333862-28',
  },
  {
    id: '32',
    type: 'price',
    face: { x: -0.10231486875152468, y: 0.2646867089825463, z: 0.9588913461494724 },
    position: { x: 5.305014758758155, y: 1.5233637962392754, z: 5.678923550082781 },
    discount: '',
    price: 69.99,
    productId: '6334442-02',
  },
  {
    id: '33',
    type: 'price',
    face: { x: -0.018187376358465707, y: 0.4310135077363741, z: 0.9021621669577938 },
    position: { x: 4.858548462831306, y: 1.8599453931200298, z: 2.699393672655012 },
    discount: '',
    price: 24.99,
    productId: '6333008-12',
  },
  {
    id: '34',
    type: 'price',
    face: { x: 0.04083077618225414, y: 0.012514740969010812, z: 0.9990876983402573 },
    position: { x: 3.7548504383261365, y: 1.7898359767538015, z: 2.6576316336575037 },
    discount: '',
    price: 24.99,
    productId: '669332-29',
  },
  {
    id: '35',
    type: 'price',
    face: { x: 0.008662166329627763, y: 0.2917696015135129, z: 0.9564494061408181 },
    position: { x: 3.141550819980637, y: 1.9914066279944322, z: 2.5446825526681858 },
    discount: '',
    price: 29.99,
    productId: '426358-02',
  },
  {
    id: '36',
    type: 'price',
    face: { x: 0.9389743064810803, y: 0.3354322634694705, z: 0.07623941495133606 },
    position: { x: 0.9709028601811521, y: 1.8430008251469072, z: 5.909302518355563 },
    discount: '',
    price: 44.99,
    productId: '6334690-01',
  },
  {
    id: '37',
    type: 'price',
    face: { x: 0.06925177157621767, y: -0.14928102946955416, z: 0.9863667504402535 },
    position: { x: 2.98621618395026, y: 1.3000090663800652, z: 15.629640411531119 },
    discount: '',
    price: 39.99,
    productId: '6334586-01',
  },
  {
    id: '38',
    type: 'price',
    face: { x: 0.9951549473011622, y: 0.09641920399267769, z: -0.019234551292910216 },
    position: { x: 3.2702841278561667, y: 0.788082658958056, z: 18.296748561634416 },
    discount: '',
    price: 49.99,
    productId: '3611470-16',
  },
  {
    id: '39',
    type: 'price',
    face: { x: 0.10177710522062802, y: 0.993232999449478, z: -0.05594309302766962 },
    position: { x: 3.158805673226184, y: 0.523639817268162, z: 20.851551662752023 },
    discount: '',
    price: 69.99,
    productId: '3611185-02',
  },
  {
    id: '40',
    type: 'price',
    face: { x: 0.19579752820708937, y: 0.8931781221988142, z: -0.40484091934165234 },
    position: { x: 3.329774992759451, y: 1.1821619134587413, z: 21.359125184713534 },
    discount: '',
    price: 39.99,
    productId: '3610563-44',
  },
  {
    id: '41',
    type: 'price',
    face: { x: 0.6556826701625091, y: 0.7549069519353168, z: 0.013990352686456563 },
    position: { x: 0.2322564065860182, y: 0.9388405783887243, z: 18.117769498974717 },
    discount: '',
    price: 59.99,
    productId: '3610902-108',
  },
  {
    id: '42',
    type: 'price',
    face: { x: -0.19818078925287166, y: 0.9729712415791649, z: 0.11853833907646527 },
    position: { x: -4.737223642811724, y: 0.8780306136702718, z: 18.16774357316714 },
    discount: '',
    price: 79.99,
    productId: '3611777-22',
  },
  {
    id: '43',
    type: 'price',
    face: { x: 0.9513683784531536, y: 0.2111891609449923, z: 0.22427069977767342 },
    position: { x: 3.45124074968893, y: 1.5061975359321917, z: 24.881817388229482 },
    discount: '',
    price: 39.99,
    productId: '3611853-22',
  },
  {
    id: '44',
    type: 'price',
    face: { x: 0.32716604097014884, y: 0.8562536217166086, z: -0.39974006170649207 },
    position: { x: 3.490405848138468, y: 0.9927870456146916, z: 26.434303073850554 },
    discount: '',
    price: 79.99,
    productId: '3611427-10',
  },
  {
    id: '45',
    type: 'price',
    face: { x: -0.07563224909736053, y: -0.1256073030062061, z: -0.9891928873217709 },
    position: { x: 2.3202793327107734, y: 0.8430886381609314, z: 30.2784341683877 },
    discount: '',
    price: 79.99,
    productId: '3611774-08',
  },
  {
    id: '46',
    type: 'price',
    face: { x: 0.7203396029764049, y: 0.21432187941359448, z: -0.6596794588195241 },
    position: { x: 0.1421437060737425, y: 1.3507590485912129, z: 30.712020926661403 },
    discount: '',
    price: 24.99,
    productId: '3611288-04',
  },
  {
    id: '47',
    type: 'price',
    face: { x: 0.07260891247170559, y: 0.995273493047125, z: -0.06448736207545472 },
    position: { x: -0.6596990342603872, y: 0.5035478764514956, z: 29.627069060339977 },
    discount: '',
    price: 24.99,
    productId: '3611220-02',
  },
  {
    id: '48',
    type: 'price',
    face: { x: 0.992835179816872, y: 0.009100859185270331, z: 0.11914478620606826 },
    position: { x: -7.786715921179699, y: 1.6639222310608113, z: 28.515072000101846 },
    discount: '',
    price: 69.99,
    productId: '3611149-03',
  },
  {
    id: '49',
    type: 'price',
    face: { x: 0.934657386278279, y: 0.2902753446646688, z: 0.2053187632810598 },
    position: { x: -7.914935703674547, y: 1.2154266265863916, z: 32.50968665010058 },
    discount: '',
    price: 6.99,
    productId: '3610964-19',
  },
  {
    id: '50',
    type: 'price',
    face: { x: 0.9717401925754714, y: 0.17559522560990004, z: -0.15775713890786106 },
    position: { x: -0.3316003567231466, y: 1.5354432551245882, z: 34.50403352065718 },
    discount: '',
    price: 39.99,
    productId: '3611260-12',
  },
  {
    id: '51',
    type: 'price',
    face: { x: 0.9989877216440562, y: 0.044538081860390516, z: 0.006315953500075159 },
    position: { x: -9.534278214457952, y: 0.7973886637232697, z: 36.99995744572427 },
    discount: '',
    price: 24.99,
    productId: '3611213-53',
  },
  {
    id: '52',
    type: 'price',
    face: { x: -0.9998062360112824, y: 0.011129081837664472, z: -0.016236809120102492 },
    position: { x: -6.66650949641423, y: 0.9509685979717898, z: 37.12423973628106 },
    discount: '',
    price: 69.99,
    productId: '3610331-05',
  },
  {
    id: '53',
    type: 'price',
    face: { x: 0.9990090286648228, y: 0.0362012048828899, z: 0.025892728925210853 },
    position: { x: -12.706131315548461, y: 1.7112008688130589, z: 41.181533610105376 },
    discount: '',
    price: 69.99,
    productId: '3611997-04',
  },
  {
    id: '54',
    type: 'price',
    face: { x: 0.9975038199564479, y: 0.06543831669094528, z: -0.026532166910183667 },
    position: { x: -12.75390311555833, y: 1.772777821561348, z: 43.60928698882825 },
    discount: '',
    price: 19.99,
    productId: '3610577-01',
  },
  {
    id: '55',
    type: 'price',
    face: { x: 0.22413349869086416, y: 0.044694165840415155, z: 0.9735330535243382 },
    position: { x: -7.346814532990505, y: 1.1152102955139538, z: 42.78123377243102 },
    discount: '',
    price: 39.99,
    productId: '3611755-07',
  },
  {
    id: '56',
    type: 'price',
    face: { x: -0.06235042372117408, y: 0.2328497035170049, z: -0.9705119474966977 },
    position: { x: 2.6966777596761764, y: 1.363162072450969, z: 40.88682547806365 },
    discount: '',
    price: 34.99,
    productId: '6332798-142',
  },
  {
    id: '57',
    type: 'price',
    face: { x: 0.5414852913664048, y: 0.28994733352400626, z: -0.7891287746725234 },
    position: { x: 5.923055144687927, y: 1.301069331650909, z: 44.72255356519807 },
    discount: '',
    price: 19.99,
    productId: '6333443-11',
  },
  {
    id: '58',
    type: 'price',
    face: { x: -0.18074816056044282, y: 0.5691916570514378, z: -0.8020916157129776 },
    position: { x: 6.676535616687751, y: 1.574173790213979, z: 44.80899327134079 },
    discount: '',
    price: 24.99,
    productId: '6333250-13',
  },
  {
    id: '59',
    type: 'price',
    face: { x: -0.946566950884899, y: -0.3221083075823053, z: 0.016038880226762982 },
    position: { x: 8.073585003590997, y: 1.038110675732193, z: 44.51163606922191 },
    discount: '',
    price: 24.99,
    productId: '6334558-13',
  },
  {
    id: '60',
    type: 'price',
    face: { x: -0.9549765555967485, y: 0.17854981343099577, z: -0.23693826703242094 },
    position: { x: 5.481447335427822, y: 1.313314922903151, z: 42.788878101224284 },
    discount: '',
    price: 34.99,
    productId: '6334601-01',
  },
  {
    id: '61',
    type: 'price',
    face: { x: -0.9803256293116317, y: 0.13931386823906433, z: 0.1398331385295421 },
    position: { x: 5.446886119924472, y: 1.2539871530789006, z: 42.35606120258864 },
    discount: '',
    price: 29.99,
    productId: '668186-09',
  },
  {
    id: '62',
    type: 'price',
    face: { x: 0.12607845999670622, y: 0.4060407191608503, z: -0.9051161010103612 },
    position: { x: 4.476406147161245, y: 1.4184808006534875, z: 41.99981511779964 },
    discount: '',
    price: 24.99,
    productId: '6335042-08',
  },
  {
    id: '63',
    type: 'price',
    face: { x: -0.9585689887922672, y: 0.11545008424418379, z: -0.2604165351389603 },
    position: { x: 7.790680299275963, y: 1.0375938021010254, z: 40.60940155441629 },
    discount: '',
    price: 11.95,
    productId: '661763-02',
  },
  {
    id: '64',
    type: 'price',
    face: { x: 0.07321937940446645, y: 0.9808693475755436, z: 0.1803725186006786 },
    position: { x: 5.645591806521744, y: 0.4734057043800537, z: 38.59653158101305 },
    discount: '',
    price: 19.99,
    productId: '6332831-39',
  },
  {
    id: '65',
    type: 'price',
    face: { x: -0.9748117410706197, y: 0.21113649013897348, z: 0.071857163892419 },
    position: { x: 18.723851408702377, y: 0.54175521130993, z: 30.633845264745997 },
    discount: '',
    price: 69.99,
    productId: '4589889-32',
  },
  {
    id: '66',
    type: 'price',
    face: { x: -0.1154780250159892, y: -0.20077016660140026, z: -0.9728083911753909 },
    position: { x: 25.170962110796538, y: 1.0525821442603274, z: 33.95158617830067 },
    discount: '',
    price: 59.99,
    productId: '2054857-20',
  },
  {
    id: '67',
    type: 'price',
    face: { x: 0.053934495294195695, y: 0.3681363026511976, z: -0.9282061909337096 },
    position: { x: 5.386746855503485, y: 1.4018717025729133, z: 34.283586400313325 },
    discount: '',
    price: 139.3,
    productId: '6334654-08',
  },
];

export default tags;
