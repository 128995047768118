import { myEmitter } from '../seed-works/myEmitter';

class CameraStateObserver {
  constructor() {
    this.currentState = 0;
  }

  changeStateToUpdate = () => {
    if (this.currentState != CameraState.Update) {
      this.currentState = CameraState.Update;
      myEmitter.emit(CameraStateObserverEventName.Update);
    }
  };
  changeStateToSleep = () => {
    if (this.currentState != CameraState.Sleep) {
      this.currentState = CameraState.Sleep;
      myEmitter.emit(CameraStateObserverEventName.Sleep);
    }
  };

  onChangeToUpdate = (onChangeToUpdate) => {
    if (onChangeToUpdate) {
      myEmitter.on(CameraStateObserverEventName.Update, onChangeToUpdate);
    }
  };
  offChangeToUpdated = (onChangeToUpdate) => {
    if (onChangeToUpdate) {
      myEmitter.off(CameraStateObserverEventName.Update, onChangeToUpdate);
    }
  };

  onChangeToSleep = (onChangeToSleep) => {
    if (onChangeToSleep) {
      myEmitter.on(CameraStateObserverEventName.Sleep, onChangeToSleep);
    }
  };
  offChangeToSleep = (onChangeToSleep) => {
    if (onChangeToSleep) {
      myEmitter.off(CameraStateObserverEventName.Sleep, onChangeToSleep);
    }
  };
}

const CameraStateObserverEventName = {
  Update: 'CSOUpdate',
  Sleep: 'CSOSleep',
};

const CameraState = {
  Update: 'update',
  Sleep: 'sleep',
};

var cameraStateObserver = new CameraStateObserver();

export { cameraStateObserver, CameraState };
