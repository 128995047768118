// useCaches
import resourceManager from '@utils/resource.manager';
import { useEffect, useState } from 'react';

export const useCaches = (spaceId, sourceUrl) => {
  const [url, setUrl] = useState();

  useEffect(() => {
    const getUrl = async () => {
      const url = await resourceManager.getResource(spaceId, sourceUrl);
      setUrl(url);
    };

    if (spaceId && sourceUrl) {
      getUrl();
    }
  }, [spaceId, sourceUrl]);

  return url;
};
