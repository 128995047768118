import productService from '@apis/services/product.service';
import useModal from '@features/layout/modal/hooks/useModal';
import React, { useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { Box, Divider, Grid, IconButton, Typography, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import ProductItem from './ProductItem';

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    height: 'calc(100% - 96px)',
  },
  scrollWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingRight: 16,
    marginRight: -16,
    maxHeight: '100%',
    height: '100%',
  },
  closeIconButton: {
    background: 'transparent',
    padding: 0,
    border: 'none',
    color: '#000',
    '& .MuiSvgIcon-root': {
      width: 24,
      height: 24,
    },
    '&:hover': {
      background: 'transparent',
    },
  },
  title: {
    fontWeight: 500,
    fontSize: '1.6rem',
  },
}));

const CategoryModalBody = (props) => {
  const { category } = props;
  const classes = useStyles();
  const [products, setProducts] = useState();
  const { close } = useModal();

  useEffect(() => {
    if (category) {
      let product = productService.getProductsByGroup(category);
      // console.log('====================================');
      // console.log(product);
      // console.log('====================================');
      setProducts(product);
    }
  }, [category]);

  return (
    <Grid className={classes.contentContainer}>
      <Grid item container alignItems="center">
        <Grid item xs>
          <Typography variant="h5" component="span" className={classes.title}>
            {category}
          </Typography>
        </Grid>
        <Grid item>
          <IconButton disableRipple className={classes.closeIconButton} onClick={close}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Grid item xs>
        <Box mb={2} mt={1}>
          <Divider />
        </Box>
      </Grid>

      <PerfectScrollbar className={classes.scrollWrapper} options={{ suppressScrollX: true }}>
        <Grid
          container
          style={{
            height: '100%',
          }}
          spacing={4}
          alignContent="flex-start"
          alignItems="baseline"
        >
          {products &&
            products.map((item) => {
              return <ProductItem key={item.id} product={item} xs={4} />;
            })}
        </Grid>
      </PerfectScrollbar>
    </Grid>
  );
};

export default CategoryModalBody;
