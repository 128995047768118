import DataStorage from '@apis/data/DataStorage';

class ProductsRepository {
  constructor() {
    this.products = [];
    this.refreshAt;
  }

  reloadProductsIfNeeded = () => {
    if (this.refreshAt != DataStorage.refreshAt) {
      this.products = DataStorage.products;
      this.refreshAt = DataStorage.refreshAt;
    }
  };

  getProducts = () => {
    // this.products = DataStorage.products;
    this.reloadProductsIfNeeded();
    return this.products;
  };

  getProductById = async (id) => {
    this.reloadProductsIfNeeded();
    // const product = this.products?.find((x) => x.sku == id);
    const product = await DataStorage.getProductById(id);
    // console.log('product >>>', product);
    return product;
  };

  getProductsByGroup = (group) => {
    this.reloadProductsIfNeeded();
    const productList = this.products.filter((x) => x.group == group);
    return productList;
  };
}

export default new ProductsRepository();
