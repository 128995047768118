import axios from 'axios';

class DataStorage {
  constructor() {
    this.storeId = '';
    this.store;
    this.locations;
    this.products;
    this.tagGroups;
    this.tags;
    this.medias;
    this.refreshAt;
    this.host = process.env.REACT_APP_FIDELITY_API_URL;
    this.productHost = process.env.REACT_APP_ECOMMERCE_API_URL;
  }

  initialize = async (storeId, spaceId) => {
    if (this.storeId != storeId) {
      const storeData = await require(`./${storeId}/store.js`);
      // const locationsData = await require(`./${storeId}/locations.js`);
      const productsData = await require(`./${storeId}/products.js`);
      // const tagGroupsData = await require(`./${storeId}/tag-groups.js`);
      const tagsData = await require(`./${storeId}/tags.js`);
      // const mediasData = await require(`./${storeId}/medias.js`);
      // const storeData = await this._getDataByStoreId(
      //   `${this.host}/api/v1/Store/GetStoreDetailsById?StoreId=` + storeId
      // );
      // const locationsData = await this._getDataByStoreId(
      //   `${this.host}/api/v1/Location/GetLocationPointsByStoreId?StoreId=` + storeId
      // );
      // const productsData = await this._getDataByStoreId(
      //   `${this.productHost}/api/v1/Products/GetProductsDetailsByCategoryId?CategoryId=` + 9
      // );
      // const tagsData = await this._getDataByStoreId(
      //   `${this.host}/api/v1/Tag/GetTagsByStoreId?StoreId=` + storeId
      // );
      // const mediasData = await this._getDataByStoreId(
      //   `${this.host}/api/v1/Media/GetMediasByStoreId?StoreId=` + storeId
      // );

      // this.store = storeData;
      // this.locations = await apiLocation.getEnabledLocationsBySpaceId(spaceId);
      // // this.products = productsData;
      // this.tags = tagsData;
      // this.medias = mediasData;
      this.store = storeData.default;
      this.products = productsData.default;
      // this.tagGroups = tagGroupsData.default;
      this.tags = tagsData.default;
      // this.medias = mediasData.default;

      this.storeId = storeId;
      this.refreshAt = new Date();
      // console.log(
      //   'initialize data storage',
      //   this.store,
      //   this.locations,
      //   this.products,
      //   this.tagGroups,
      //   this.tags,
      //   this.medias
      // );
    }
  };

  // initializeProducts = async (storeId) => {
  //   if (this.storeId != storeId) {
  //     const productsData = await this._getDataByStoreId(
  //       `${this.productHost}/api/v1/Products/GetProductsDetailsByCategoryId?CategoryId=` + 9
  //     );
  //     const tagsData = await this._getDataByStoreId(
  //       `${this.host}/api/v1/Tag/GetTagsByStoreId?StoreId=` + storeId
  //     );

  //     this.products = productsData;
  //     this.tags = tagsData;
  //     this.refreshAt = new Date();
  //   }
  // };

  getProductById = async (id) => {
    try {
      const res = await axios(
        `${this.productHost}/api/v1/Products/GetProductDetailsById?ProductId=` + id
      );
      if (res) {
        console.log('Data', res.data.data);
        return res.data.data;
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  _getDataByStoreId = async (url) => {
    try {
      const res = await axios(url);
      if (res) {
        console.log('Data', res.data.data);
        return res.data.data;
      }
    } catch (error) {
      console.log('error', error);
    }
  };
}

export default new DataStorage();
