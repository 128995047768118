import { createContext } from 'react';

const TransitionStates = {
  moving: 'moving',
  stop: 'stop',
};

const TransitionContext = createContext({
  fromLocationId: '',
  toLocationId: '',
  currentLocationId: '',
  state: TransitionStates.stop,
});

export { TransitionContext, TransitionStates };
