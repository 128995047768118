import { createContext, useCallback, useContext, useEffect, useState } from 'react';

const ModalContext = createContext({
  isOpen: false,
  body: <></>,
  open: () => {},
  close: () => {},
  isPromotion: false,
  setIsPromotion: () => {},
});

export const ModalProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [body, setBody] = useState(<></>);
  const [isPromotion, setIsPromotion] = useState(false);

  const open = useCallback(
    (body) => {
      setBody(body);
      setIsOpen(true);
    },
    [isOpen]
  );

  const close = useCallback(() => {
    setIsOpen(false);
    setBody(<></>);
    setIsPromotion(false);
  }, [isOpen]);

  let contextValue = {
    isOpen: isOpen,
    body: body,
    open,
    close,
    isPromotion: isPromotion,
    setIsPromotion,
  };

  return <ModalContext.Provider value={contextValue}>{children}</ModalContext.Provider>;
};

const useModal = () => {
  const context = useContext(ModalContext);

  if (context === undefined) {
    throw new Error('useModalContext must be used within ModalContext');
  }

  return context;
};

export default useModal;
