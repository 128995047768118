import tagService from '@apis/services/tag.service';
import React, { memo, useCallback, useMemo } from 'react';
import { Vector3 } from 'three';

import CategoryTag from './CategoryTag';
import LinkTag from './LinkTag';
import ProductTag from './ProductTag';
import PromotionalTag from './PromotionalTag';
import Tag from './Tag';

export const Tags = memo((props) => {
  const tagMockData = useMemo(() => tagService.getTags(), []);
  const stemLength = 0.3;
  const vec3 = useMemo(() => new Vector3(), []);

  const getTag = useCallback(
    (tagType, tag) => {
      const faceVec3 = vec3.set(tag.face.x, tag.face.y, tag.face.z);
      faceVec3.multiplyScalar(stemLength);
      const stemEndPosition = [faceVec3.x, faceVec3.y, faceVec3.z];

      switch (tagType) {
        case 'link':
          return <LinkTag tagData={tag} stemEndPosition={stemEndPosition} />;
        case 'category':
          return <CategoryTag tagData={tag} stemEndPosition={stemEndPosition} />;
        case 'preview':
          return <ProductTag tagData={tag} stemEndPosition={stemEndPosition} />;
        case 'promotional':
          return <PromotionalTag tagData={tag} stemEndPosition={stemEndPosition} />;
        default:
          return <Tag tagData={tag} stemEndPosition={stemEndPosition} />;
      }
    },
    [tagMockData]
  );

  return (
    <>
      {tagMockData &&
        tagMockData.map((tag, index) => {
          return (
            <group
              key={`tag-${tag.id}`}
              tag={tag}
              position={[tag.position.x, tag.position.y, tag.position.z]}
            >
              {getTag(tag.type, tag)}
            </group>
          );
        })}
    </>
  );
});
