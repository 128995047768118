import { myEmitter } from '../seed-works/myEmitter';

class NavigationManager {
  constructor() {
    this.currentLocationData = undefined;
    this.toLocationData = undefined;
    this.enabledTransition = undefined;
    this.cameraOptions = undefined;
  }

  requestNewLocation = (locationData, enabledTransition, cameraOptions) => {
    if (
      this.currentLocationData == null ||
      this.currentLocationData.locationKey != locationData.locationKey
    ) {
      this.toLocationData = locationData;
      this.enabledTransition = enabledTransition;
      this.cameraOptions = cameraOptions;

      // console.log(
      //   'NavigationManager: RequestNewLocation',
      //   this.toLocationData,
      //   enabledTransition,
      //   this.cameraOptions
      // );
      myEmitter.emit(NavigationManagerEventName.RequestNewLocation, this.toLocationData);
    }
  };

  locationReady = (locationId) => {
    if (this.toLocationData && this.toLocationData.locationKey == locationId) {
      // console.log('NavigationManager: DestinationLocationReady', this.toLocationData);
      myEmitter.emit(NavigationManagerEventName.DestinationLocationReady, this.toLocationData);
    }
  };

  arrived = (locationData) => {
    this.currentLocationData = locationData;
    this.toLocationData = undefined;

    // console.log('NavigationManager: Arrived', this.currentLocationData);
    myEmitter.emit(
      NavigationManagerEventName.Arrived,
      this.currentLocationData,
      this.enabledTransition,
      this.cameraOptions
    );
  };

  rest = () => {
    myEmitter.emit(NavigationManagerEventName.Rest, this.toLocationData);
  };

  onRequestNewLocation = (onRequestNewLocation) => {
    if (onRequestNewLocation) {
      myEmitter.on(NavigationManagerEventName.RequestNewLocation, onRequestNewLocation);
    }
  };
  offRequestNewLocation = (onRequestNewLocation) => {
    if (onRequestNewLocation) {
      myEmitter.off(NavigationManagerEventName.RequestNewLocation, onRequestNewLocation);
    }
  };

  onDestinationLocationReady = (onDestinationLocationReady) => {
    if (onDestinationLocationReady) {
      myEmitter.on(NavigationManagerEventName.DestinationLocationReady, onDestinationLocationReady);
    }
  };
  offDestinationLocationReady = (onDestinationLocationReady) => {
    if (onDestinationLocationReady) {
      myEmitter.off(
        NavigationManagerEventName.DestinationLocationReady,
        onDestinationLocationReady
      );
    }
  };

  onArrived = (onArrived) => {
    if (onArrived) {
      myEmitter.on(NavigationManagerEventName.Arrived, onArrived);
    }
  };
  offArrived = (onArrived) => {
    if (onArrived) {
      myEmitter.off(NavigationManagerEventName.Arrived, onArrived);
    }
  };

  onceRest = (onceRest) => {
    if (onceRest) {
      myEmitter.once(NavigationManagerEventName.Rest, onceRest);
    }
  };

  clearAllListeners = () => {
    myEmitter.removeAllListeners(NavigationManagerEventName.RequestNewLocation);
    myEmitter.removeAllListeners(NavigationManagerEventName.DestinationLocationReady);
    myEmitter.removeAllListeners(NavigationManagerEventName.Arrived);
  };
}

const NavigationManagerEventName = {
  RequestNewLocation: 'NMRequestNewLocation',
  DestinationLocationReady: 'NMDestinationLocationReady',
  Arrived: 'NMArrived',
  Rest: 'NMRest',
};

export default new NavigationManager();
