import pointerManager from '@utils/pointer.manager';
import React, { useEffect, useRef, useState } from 'react';

import { useCursor } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';

export const SurfacePointer = (props) => {
  const ref = useRef();
  const maxScale = 5;
  const minScale = 0.5;
  const defaultScale = 1;
  const pointerStates = { Default: 'default', Pulsing: 'pulsing' };
  const [pointerState, setPointerState] = useState(pointerStates.Default);

  const [hovered, setHovered] = useState(false);

  useCursor(hovered);

  useEffect(() => {
    pointerManager.onPositionUpdate(handleOnPositionUpdate);
    pointerManager.onHoverFloorArea(handleOnHoverFloorArea);
    pointerManager.onChangeToDefault(handleOnChangeToDefault);
    return () => {
      pointerManager.offPositionUpdate(handleOnPositionUpdate);
      pointerManager.offHoverFloorArea(handleOnHoverFloorArea);
      pointerManager.offChangeToDefault(handleOnChangeToDefault);
    };
  }, []);

  useFrame((_, delta) => {
    if (pointerState == pointerStates.Default) {
      ref.current.scale.set(defaultScale, defaultScale, defaultScale);
      ref.current.children[0].material.opacity = 1;
    } else if (pointerState == pointerStates.Pulsing) {
      if (ref.current.scale.x > maxScale) {
        //Restart
        ref.current.scale.set(minScale, minScale, defaultScale);
        ref.current.children[0].material.opacity = 1;
      } else {
        //Increase size
        let newScale = ref.current.scale.x + delta * 3;
        ref.current.scale.set(newScale, newScale, defaultScale);
        ref.current.children[0].material.opacity -= delta / 2;
      }
    }
  });

  const handleOnPositionUpdate = (point, face) => {
    ref.current?.position.set(0, 0, 0);
    ref.current?.lookAt(face);
    ref.current?.position.set(point.x, point.y, point.z);
  };

  const handleOnHoverFloorArea = () => {
    setPointerState(pointerStates.Pulsing);
    setHovered(true);
  };

  const handleOnChangeToDefault = () => {
    setPointerState(pointerStates.Default);
    setHovered(false);
  };

  return (
    <group ref={ref}>
      <mesh scale={0.1} rotation={[0, 0, 0]} position={[0, 0, 0.01]}>
        <ringGeometry args={[0.25, 0.5, 16]} />
        <meshStandardMaterial transparent={true} opacity={1} depthWrite={true} />
      </mesh>
    </group>
  );
};
