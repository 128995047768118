const store = {
  id: 'cotton-on-chadstone2',
  name: 'Cotton on',
  model:
    'https://fidelitystorageprod-cdn.azureedge.net/cottonon-vr-store-3d/cotton-on-chadstone2/store-models/ct-chadstone-D.glb',
  modelId: 'ea7d02b5f95f43cca81ffcd9c0ce4c55',
  storeFrontId: '757cb6544d2b470b8966dd5569a508cf',
  storeLogo:
    'https://fidelitystorageprod-cdn.azureedge.net/cottonon-vr-store-3d/cotton-on-chadstone2/cotton-on-logo.svg',
  storeWelcomeText: 'Welcome to the Cotton On Experience.',
  cameraRotation: { azimuthal: 90, polar: 85 },
  rotationDegrees: -6,
  navigationMenu: [
    {
      title: 'Entrance',
      locationId: '757cb6544d2b470b8966dd5569a508cf',
      options: {
        rotation: { azimuthal: 1.4835298641951802, polar: 1.5707963267948966 },
      },
    },
    {
      title: 'Mens',
      menu: [
        {
          title: 'Clothing',
          locationId: 'd15cf28e179a4219b5c4ec71eec117b4',
          options: {
            rotation: { azimuthal: 1.6185431592974282, polar: 1.371708925542433 },
          },
        },
        {
          title: 'Activewear',
          locationId: '82feccb21151465c8e9fd41c2898f8ea',
          options: {
            rotation: { azimuthal: 1.4354742845825192, polar: 1.3053464584582786 },
          },
        },
        {
          title: 'Sleepwear',
          locationId: 'ddf0842dd50e47fca333c8573fdd5c81',
          options: {
            rotation: { azimuthal: 1.7215194013245645, polar: 1.4128994223532876 },
          },
        },
        {
          title: 'Denim',
          locationId: '782152a22430431ba31fb72b3988c578',
          options: {
            rotation: { azimuthal: 0.062457724220692984, polar: 1.4220528660890308 },
          },
        },
      ],
    },
    {
      title: 'Ladies',
      menu: [
        {
          title: 'Clothing',
          locationId: 'c2be6cf8f0754042b130273682bc25a3',
          options: {
            rotation: { azimuthal: -3.134382500488416, polar: 1.2298305476383722 },
          },
        },
        {
          title: 'Activewear',
          locationId: 'b89876d1fbd540d5b101df125a1129fc',
          options: {
            rotation: { azimuthal: -0.3448705220199757, polar: 1.3328067896655085 },
          },
        },
        {
          title: 'Sleepwear',
          locationId: '31984829b8c84e01bf494ac1d41e0e01',
          options: {
            rotation: { azimuthal: -2.3723583094876015, polar: 1.1405844712148525 },
          },
        },
        {
          title: 'Intimates',
          locationId: '14ee3c31f9c042bfbe0037d39f7e6fdc',
          options: {
            rotation: { azimuthal: -3.063443311536392, polar: 1.3808623692781699 },
          },
        },
        {
          title: 'Accessories',
          locationId: 'c1cb4ff73c254c128a4cc42f9b0ff247',
          options: {
            rotation: { azimuthal: -4.605798581009506, polar: 1.2664443225813504 },
          },
        },
      ],
    },
  ],
  panoImageBaseUrl:
    'https://fidelitystorageprod-cdn.azureedge.net/cottonon-vr-store-3d/cotton-on-chadstone2/location-images/',
  panoImageFormat: '.jpg',
};

export default store;
