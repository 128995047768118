import { Layout } from '@features/layout';
import MainView from '@pages/Space/MainView';
import React from 'react';
import { Route, Routes } from 'react-router';

import { AppProvider } from './providers/AppProvider';

export default function App() {
  return (
    <AppProvider>
      <AppContent />
    </AppProvider>
  );
}

function AppContent() {
  return (
    <Routes>
      <Route path="/" element={<MainView />} />
      <Route path="/:spaceAlias" element={<MainView />} />
      <Route path="/:spaceAlias/:locationId" element={<MainView />} />
    </Routes>
  );
}
