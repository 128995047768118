import { extend } from '@react-three/fiber';
import * as THREE from 'three';

export default class ImageShader extends THREE.ShaderMaterial {
  constructor() {
    super({
      uniforms: {
        texture1: { type: 't', value: undefined },
        dispFactor: { type: 'f', value: undefined },
      },
      vertexShader: `
        varying vec2 vUv;
        void main() {
          vUv = uv;
          gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
        }`,
      fragmentShader: `
        varying vec2 vUv;
        uniform sampler2D texture1;
        uniform float dispFactor;
        void main() {
          vec2 uv = vUv;
          vec2 distortedPosition = vec2(uv.x, uv.y );
          vec4 _texture1 = texture2D(texture1, distortedPosition);
          //_texture1 = LinearTosRGB(_texture1);
          vec4 colorA = vec4(0,0,0, 1.0);
          vec4 finalTexture = mix(colorA, _texture1, dispFactor);
          gl_FragColor = finalTexture;
        }`,
    });
  }

  get texture1() {
    return this.uniforms.texture1.value;
  }
  set texture1(v) {
    this.uniforms.texture1.value = v;
  }
  get dispFactor() {
    return this.uniforms.dispFactor.value;
  }
  set dispFactor(v) {
    this.uniforms.dispFactor.value = v;
  }
}

// register element in r3f (<imageShader />)
extend({ ImageShader });
