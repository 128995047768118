import React, { createContext, useContext, useState } from 'react';

const XYZControlContext = createContext({
  value: { x: 0, y: 0, z: 0 },
  setValue: (value) => {},
});

export const XYZControlProvider = ({ children }) => {
  const [xyz, setXYZ] = useState({ x: 0, y: 0, z: 0 });

  let contextValue = {
    value: xyz,
    setValue: setXYZ,
  };

  return <XYZControlContext.Provider value={contextValue}>{children}</XYZControlContext.Provider>;
};

const useXYZControl = () => {
  const context = useContext(XYZControlContext);

  if (context === undefined) {
    throw new Error('useXYZControl must be used within XYZControlContext');
  }

  return context;
};
export default useXYZControl;
