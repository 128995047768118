const products = [
  {
    id: '5298895-02',
    sku: '5298895-02',
    title: 'Contrast Bind V Neck Tank',
    price: '14.95',
    group: "Women's Basic Tops & Tanks",
    imagePreview:
      'https://factorie.com.au/dw/image/v2/BBDS_PRD/on/demandware.static/-/Sites-catalog-master-factorie/default/dw78373118/5298895/5298895-02-2.jpg?sw=640&sh=960&sm=fit',
    imageHoveredPreview:
      'https://factorie.com.au/dw/image/v2/BBDS_PRD/on/demandware.static/-/Sites-catalog-master-factorie/default/dw573f6ede/5298895/5298895-02-1.jpg?sw=640&sh=960&sm=fit',
    url:
      'https://factorie.com.au/contrast-bind-v-neck-tank/5298895-02.html?dwvar_5298895-02_color=5298895-02&cgid=womens-basics&originalPid=5298895-02',
    infoTables: [
      {
        name: 'Composition',
        headers: [],
        rows: [['Main', '95% Cotton, 5% Elastane']],
      },
      {
        name: 'Dimensions',
        headers: ['Size', 'Bust Circumference x Front Length'],
        rows: [
          ['XXS', '56.0 x 25.0 cm'],
          ['XS', '61.0 x 25.5 cm'],
          ['S', '66.0 x 26.0 cm'],
          ['M', '71.0 x 26.5 cm'],
          ['L', '76.0 x 27.0 cm'],
          ['XL', '81.0 x 27.5 cm'],
        ],
      },
    ],
  },
  {
    id: '5298842-02',
    sku: '5298842-02',
    title: 'Short Sleeve Fitted Polo Tee',
    price: '19.95',
    group: "Women's Basic Tops & Tanks",
    imagePreview:
      'https://factorie.com.au/dw/image/v2/BBDS_PRD/on/demandware.static/-/Sites-catalog-master-factorie/default/dwe13b76bf/5298842/5298842-02-2.jpg?sw=640&sh=960&sm=fit',
    imageHoveredPreview:
      'https://factorie.com.au/dw/image/v2/BBDS_PRD/on/demandware.static/-/Sites-catalog-master-factorie/default/dw33c9cb37/5298842/5298842-02-1.jpg?sw=640&sh=960&sm=fit',
    url:
      'https://factorie.com.au/short-sleeve-fitted-polo-tee/5298842-02.html?dwvar_5298842-02_color=5298842-02&cgid=womens-basics&originalPid=5298842-02',
  },
  {
    id: '5298541-10',
    sku: '5298541-10',
    title: 'Longline Tank',
    price: '14.95',
    group: "Women's Basic Tops & Tanks",
    imagePreview:
      'https://factorie.com.au/dw/image/v2/BBDS_PRD/on/demandware.static/-/Sites-catalog-master-factorie/default/dw5bc5e3d2/5298541/5298541-10-2.jpg?sw=640&sh=960&sm=fit',
    imageHoveredPreview:
      'https://factorie.com.au/dw/image/v2/BBDS_PRD/on/demandware.static/-/Sites-catalog-master-factorie/default/dw0e3029e3/5298541/5298541-10-1.jpg?sw=640&sh=960&sm=fit',
    url:
      'https://factorie.com.au/longline-tank/5298541-10.html?dwvar_5298541-10_color=5298541-10&cgid=womens-basics&originalPid=5298541-10',
  },
  {
    id: '5298813-03',
    sku: '5298813-03',
    title: 'Pointelle Corset Tank',
    price: '24.95',
    group: "Women's Basic Tops & Tanks",
    imagePreview:
      'https://factorie.com.au/dw/image/v2/BBDS_PRD/on/demandware.static/-/Sites-catalog-master-factorie/default/dwc9e965d4/5298813/5298813-03-2.jpg?sw=640&sh=960&sm=fit',
    imageHoveredPreview:
      'https://factorie.com.au/dw/image/v2/BBDS_PRD/on/demandware.static/-/Sites-catalog-master-factorie/default/dw0ba551ad/5298813/5298813-03-1.jpg?sw=640&sh=960&sm=fit',
    url:
      'https://factorie.com.au/pointelle-corset-tank/5298813-03.html?dwvar_5298813-03_color=5298813-03&cgid=womens-basics&originalPid=5298813-03',
  },
  {
    id: '5298106-08',
    sku: '5298106-08',
    title: 'Fitted Crop Tee',
    price: '19.95',
    group: "Women's Basic Tops & Tanks",
    imagePreview:
      'https://factorie.com.au/dw/image/v2/BBDS_PRD/on/demandware.static/-/Sites-catalog-master-factorie/default/dwda14386d/5298106/5298106-08-2.jpg?sw=640&sh=960&sm=fit',
    imageHoveredPreview:
      'https://factorie.com.au/dw/image/v2/BBDS_PRD/on/demandware.static/-/Sites-catalog-master-factorie/default/dw302cf0e8/5298106/5298106-08-1.jpg?sw=640&sh=960&sm=fit',
    url:
      'https://factorie.com.au/fitted-crop-tee/5298106-08.html?dwvar_5298106-08_color=5298106-08&cgid=womens-basics&originalPid=5298106-08',
  },
  {
    id: '5298003-03',
    sku: '5298003-03',
    title: 'Racer Front Tank',
    price: '14.95',
    group: "Women's Basic Tops & Tanks",
    imagePreview:
      'https://factorie.com.au/dw/image/v2/BBDS_PRD/on/demandware.static/-/Sites-catalog-master-factorie/default/dw2469065e/5298003/5298003-03-2.jpg?sw=640&sh=960&sm=fit',
    imageHoveredPreview:
      'https://factorie.com.au/dw/image/v2/BBDS_PRD/on/demandware.static/-/Sites-catalog-master-factorie/default/dwd9db0141/5298003/5298003-03-1.jpg?sw=640&sh=960&sm=fit',
    url:
      'https://factorie.com.au/racer-front-tank/5298003-03.html?dwvar_5298003-03_color=5298003-03&cgid=womens-basics&originalPid=5298003-03',
    infoTables: [
      {
        name: 'Composition',
        headers: [],
        rows: [['Main', '95% Cotton, 5% Elastane']],
      },
      {
        name: 'Dimensions',
        headers: ['Size', 'Bust Circumference x Front Length'],
        rows: [
          ['XXS', '53.0 x 46.0 cm'],
          ['XS', '58.0 x 47.0 cm'],
          ['S', '63.0 x 48.0 cm'],
          ['M', '68.0 x 49.0 cm'],
          ['L', '73.0 x 50.0 cm'],
          ['XL', '78.0 x 51.0 cm'],
        ],
      },
    ],
  },
  {
    id: '5298640-05',
    sku: '5298640-05',
    title: 'Raglan Curve Hem Tank',
    price: '19.95',
    group: "Women's Basic Tops & Tanks",
    imagePreview:
      'https://factorie.com.au/dw/image/v2/BBDS_PRD/on/demandware.static/-/Sites-catalog-master-factorie/default/dwb2bc99aa/5298640/5298640-05-2.jpg?sw=640&sh=960&sm=fit',
    imageHoveredPreview:
      'https://factorie.com.au/dw/image/v2/BBDS_PRD/on/demandware.static/-/Sites-catalog-master-factorie/default/dw792373d9/5298640/5298640-05-1.jpg?sw=640&sh=960&sm=fit',
    url:
      'https://factorie.com.au/raglan-curve-hem-tank/5298640-05.html?dwvar_5298640-05_color=5298640-05&cgid=womens-basics&originalPid=5298640-05',
  },
  {
    id: '5298003-19',
    sku: '5298003-19',
    title: 'New Racer Front Tank',
    price: '14.95',
    group: "Women's Basic Tops & Tanks",
    imagePreview:
      'https://factorie.com.au/dw/image/v2/BBDS_PRD/on/demandware.static/-/Sites-catalog-master-factorie/default/dw8b8a1276/5298003/5298003-19-2.jpg?sw=640&sh=960&sm=fit',
    imageHoveredPreview:
      'https://factorie.com.au/dw/image/v2/BBDS_PRD/on/demandware.static/-/Sites-catalog-master-factorie/default/dw93876670/5298003/5298003-19-1.jpg?sw=640&sh=960&sm=fit',
    url:
      'https://factorie.com.au/new-racer-front-tank/5298003-19.html?dwvar_5298003-19_color=5298003-19&cgid=womens-basics&originalPid=5298003-19',
  },
  {
    id: '5298106-09',
    sku: '5298106-09',
    title: 'Fitted Crop Tee',
    price: '19.95',
    group: "Women's Basic Tops & Tanks",
    imagePreview:
      'https://factorie.com.au/dw/image/v2/BBDS_PRD/on/demandware.static/-/Sites-catalog-master-factorie/default/dw627404c1/5298106/5298106-09-2.jpg?sw=640&sh=960&sm=fit',
    imageHoveredPreview:
      'https://factorie.com.au/dw/image/v2/BBDS_PRD/on/demandware.static/-/Sites-catalog-master-factorie/default/dwdc4680ba/5298106/5298106-09-1.jpg?sw=640&sh=960&sm=fit',
    url:
      'https://factorie.com.au/fitted-crop-tee/5298106-09.html?dwvar_5298106-09_color=5298106-09&cgid=womens-basics&originalPid=5298106-09',
  },
  {
    id: '5298643-05',
    sku: '5298643-05',
    title: 'Rib Corset Tank',
    price: '16.95',
    group: "Women's Basic Tops & Tanks",
    imagePreview:
      'https://factorie.com.au/dw/image/v2/BBDS_PRD/on/demandware.static/-/Sites-catalog-master-factorie/default/dw5d53438c/5298643/5298643-05-2.jpg?sw=640&sh=960&sm=fit',
    imageHoveredPreview:
      'https://factorie.com.au/dw/image/v2/BBDS_PRD/on/demandware.static/-/Sites-catalog-master-factorie/default/dw211d473a/5298643/5298643-05-1.jpg?sw=640&sh=960&sm=fit',
    url:
      'https://factorie.com.au/rib-corset-tank/5298643-05.html?dwvar_5298643-05_color=5298643-05&cgid=womens-basics&originalPid=5298643-05',
  },
  {
    id: '5298466-07',
    sku: '5298466-07',
    title: 'Crop Jersey Bandeau',
    price: '14.95',
    group: "Women's Basic Tops & Tanks",
    imagePreview:
      'https://factorie.com.au/dw/image/v2/BBDS_PRD/on/demandware.static/-/Sites-catalog-master-factorie/default/dw18260022/5298466/5298466-07-2.jpg?sw=640&sh=960&sm=fit',
    imageHoveredPreview:
      'https://factorie.com.au/dw/image/v2/BBDS_PRD/on/demandware.static/-/Sites-catalog-master-factorie/default/dwcc2f8dec/5298466/5298466-07-1.jpg?sw=640&sh=960&sm=fit',
    url:
      'https://factorie.com.au/crop-jersey-bandeau/5298466-07.html?dwvar_5298466-07_color=5298466-07&cgid=womens-basics&originalPid=5298466-07',
  },
  {
    id: '5298413-02',
    sku: '5298413-02',
    title: 'Utility Mesh Pant',
    price: '59.95',
    group: 'Men',
    imagePreview:
      'https://factorie.com.au/dw/image/v2/BBDS_PRD/on/demandware.static/-/Sites-catalog-master-factorie/default/dwa53d174a/5298413/5298413-02-2.jpg?sw=640&sh=960&sm=fit',
    imageHoveredPreview:
      'https://factorie.com.au/dw/image/v2/BBDS_PRD/on/demandware.static/-/Sites-catalog-master-factorie/default/dwbeedd9bd/5298413/5298413-02-1.jpg?sw=640&sh=960&sm=fit',
    url:
      'https://factorie.com.au/utility-mesh-pant/5298413-02.html?dwvar_5298413-02_color=5298413-02&cgid=mens-pants&originalPid=5298413-02',
    infoTables: [
      {
        name: 'Composition',
        headers: [],
        rows: [['Main', '100% Polyamide']],
      },
      {
        name: 'Dimensions',
        headers: ['Size', 'Waist Cir. x Seat Cir. x Front Rise x Inleg'],
        rows: [
          ['XXS', '65.0 x 102.0 x 30.0 x 78.0 cm'],
          ['XS', '70.0 x 107.0 x 31.0 x 78.0 cm'],
          ['S', '75.0 x 112.0 x 32.0 x 78.0 cm'],
          ['M', '80.0 x 117.0 x 33.0 x 78.0 cm'],
          ['L', '85.0 x 122.0 x 34.0 x 78.0 cm'],
          ['XL', '90.0 x 127.0 x 35.0 x 78.0 cm'],
        ],
      },
    ],
  },
  {
    id: '5298746-02',
    sku: '5298746-02',
    title: 'Lcn Nfl X Betty Slim Fit Graphic Tee',
    price: '29.95',
    group: "Women's Graphic Tees",
    imagePreview:
      'https://factorie.com.au/dw/image/v2/BBDS_PRD/on/demandware.static/-/Sites-catalog-master-factorie/default/dw51b555d3/5298746/5298746-02-2.jpg?sw=640&sh=960&sm=fit',
    imageHoveredPreview:
      'https://factorie.com.au/dw/image/v2/BBDS_PRD/on/demandware.static/-/Sites-catalog-master-factorie/default/dw866e8454/5298746/5298746-02-1.jpg?sw=640&sh=960&sm=fit',
    url:
      'https://factorie.com.au/lcn-nfl-x-betty-slim-fit-graphic-tee/5298746-02.html?dwvar_5298746-02_color=5298746-02&cgid=womens-graphic-tees&originalPid=5298746-02',
    infoTables: [
      {
        name: 'Composition',
        headers: [],
        rows: [['Main', '100% Cotton.']],
      },
      {
        name: 'Dimensions',
        headers: ['Size', 'Bust Circumference x Front Length'],
        rows: [
          ['XXS', '56.0 x 25.0 cm'],
          ['XS', '61.0 x 25.5 cm'],
          ['S', '66.0 x 26.0 cm'],
          ['M', '71.0 x 26.5 cm'],
          ['L', '76.0 x 27.0 cm'],
          ['XL', '81.0 x 27.5 cm'],
        ],
      },
    ],
  },
  {
    id: '5298745-02',
    sku: '5298745-02',
    title: 'Lcn Nfl X Betty Oversized Graphic Tee',
    price: '39.95',
    group: "Women's Graphic Tees",
    imagePreview:
      'https://factorie.com.au/dw/image/v2/BBDS_PRD/on/demandware.static/-/Sites-catalog-master-factorie/default/dwa37cb775/5298745/5298745-02-2.jpg?sw=640&sh=960&sm=fit',
    imageHoveredPreview:
      'https://factorie.com.au/dw/image/v2/BBDS_PRD/on/demandware.static/-/Sites-catalog-master-factorie/default/dw01fb8bdd/5298745/5298745-02-1.jpg?sw=640&sh=960&sm=fit',
    url:
      'https://factorie.com.au/lcn-nfl-x-betty-oversized-graphic-tee/5298745-02.html?dwvar_5298745-02_color=5298745-02&cgid=womens-graphic-tees&originalPid=5298745-02',
  },
  {
    id: '5298675-01',
    sku: '5298675-01',
    title: 'Music Merch Oversized Graphic Tee',
    price: '34.95',
    group: "Women's Graphic Tees",
    imagePreview:
      'https://factorie.com.au/dw/image/v2/BBDS_PRD/on/demandware.static/-/Sites-catalog-master-factorie/default/dwf1b957a6/5298675/5298675-01-2.jpg?sw=640&sh=960&sm=fit',
    imageHoveredPreview:
      'https://factorie.com.au/dw/image/v2/BBDS_PRD/on/demandware.static/-/Sites-catalog-master-factorie/default/dw2c157703/5298675/5298675-01-1.jpg?sw=640&sh=960&sm=fit',
    url:
      'https://factorie.com.au/music-merch-oversized-graphic-tee/5298675-01.html?dwvar_5298675-01_color=5298675-01&cgid=womens-graphic-tees&originalPid=5298675-01',
  },
  {
    id: '5298675-06',
    sku: '5298675-06',
    title: 'Music Merch Oversized Graphic Tee',
    price: '34.95',
    group: "Women's Graphic Tees",
    imagePreview:
      'https://factorie.com.au/dw/image/v2/BBDS_PRD/on/demandware.static/-/Sites-catalog-master-factorie/default/dwcb9b4661/5298675/5298675-06-2.jpg?sw=640&sh=960&sm=fit',
    imageHoveredPreview:
      'https://factorie.com.au/dw/image/v2/BBDS_PRD/on/demandware.static/-/Sites-catalog-master-factorie/default/dw8c7120bd/5298675/5298675-06-1.jpg?sw=640&sh=960&sm=fit',
    url:
      'https://factorie.com.au/music-merch-oversized-graphic-tee/5298675-06.html?dwvar_5298675-06_color=5298675-06&cgid=womens-graphic-tees&originalPid=5298675-06',
  },
  {
    id: '5298677-02',
    sku: '5298677-02',
    title: 'Nascar Oversized Graphic Tee',
    price: '34.95',
    group: "Women's Graphic Tees",
    imagePreview:
      'https://factorie.com.au/dw/image/v2/BBDS_PRD/on/demandware.static/-/Sites-catalog-master-factorie/default/dw71ee48af/5298677/5298677-02-2.jpg?sw=640&sh=960&sm=fit',
    imageHoveredPreview:
      'https://factorie.com.au/dw/image/v2/BBDS_PRD/on/demandware.static/-/Sites-catalog-master-factorie/default/dw45c43f71/5298677/5298677-02-1.jpg?sw=640&sh=960&sm=fit',
    url:
      'https://factorie.com.au/nascar-oversized-graphic-tee/5298677-02.html?dwvar_5298677-02_color=5298677-02&cgid=womens-graphic-tees&originalPid=5298677-02',
  },
  {
    id: '5298637-04',
    sku: '5298637-04',
    title: 'Cropped Fitted Graphic Tee',
    price: '19.95',
    group: "Women's Graphic Tees",
    imagePreview:
      'https://factorie.com.au/dw/image/v2/BBDS_PRD/on/demandware.static/-/Sites-catalog-master-factorie/default/dw1478de61/5298637/5298637-04-2.jpg?sw=320&sh=640&sm=fit',
    imageHoveredPreview:
      'https://factorie.com.au/dw/image/v2/BBDS_PRD/on/demandware.static/-/Sites-catalog-master-factorie/default/dwad63fc89/5298637/5298637-04-1.jpg?sw=320&sh=640&sm=fit',
    url:
      'https://factorie.com.au/cropped-fitted-graphic-tee/5298637-04.html?dwvar_5298637-04_color=5298637-04&cgid=womens-graphic-tees&originalPid=5298637-04',
  },
  {
    id: '5298095-01',
    sku: '5298095-01',
    title: 'Lcn Bratz Cropped Graphic Tee',
    price: '29.95',
    group: "Women's Graphic Tees",
    imagePreview:
      'https://factorie.com.au/dw/image/v2/BBDS_PRD/on/demandware.static/-/Sites-catalog-master-factorie/default/dwe872874a/5298095/5298095-01-2.jpg?sw=320&sh=640&sm=fit',
    imageHoveredPreview:
      'https://factorie.com.au/dw/image/v2/BBDS_PRD/on/demandware.static/-/Sites-catalog-master-factorie/default/dwe055e560/5298095/5298095-01-1.jpg?sw=320&sh=640&sm=fit',
    url:
      'https://factorie.com.au/lcn-bratz-cropped-graphic-tee/5298095-01.html?dwvar_5298095-01_color=5298095-01&cgid=womens-graphic-tees&originalPid=5298095-01',
  },
  {
    id: '5298511-06',
    sku: '5298511-06',
    title: 'Lcn Music Merch Everyday Graphic Tee',
    price: '29.95',
    group: "Women's Graphic Tees",
    imagePreview:
      'https://factorie.com.au/dw/image/v2/BBDS_PRD/on/demandware.static/-/Sites-catalog-master-factorie/default/dwa958e5f4/5298511/5298511-06-2.jpg?sw=320&sh=640&sm=fit',
    imageHoveredPreview:
      'https://factorie.com.au/dw/image/v2/BBDS_PRD/on/demandware.static/-/Sites-catalog-master-factorie/default/dw5fdaf0e2/5298511/5298511-06-1.jpg?sw=320&sh=640&sm=fit',
    url:
      'https://factorie.com.au/lcn-music-merch-everyday-graphic-tee/5298511-06.html?dwvar_5298511-06_color=5298511-06&cgid=womens-graphic-tees&originalPid=5298511-06',
  },
];

export default products;
