import productService from '@apis/services/product.service';
import tagGroupsService from '@apis/services/tag-groups.service';
import tagService from '@apis/services/tag.service';
import useWindowLandscape from '@hooks/useWindowLandscape';
import React, { useEffect, useState } from 'react';

import { Box, CircularProgress, Divider, Grid, makeStyles } from '@material-ui/core';

import { ProductThumbnail } from './ProductThumbnail';

const useStyle = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    background: 'white',
  },
  title: {
    fontSize: 24,
    fontWeight: 700,
    marginRight: 12,
    textTransform: 'uppercase',
  },
  subTitle: {
    fontSize: 24,
    textTransform: 'uppercase',
  },
  scrollBox: {
    position: 'relative',
    width: 'calc(100% + 8px)',
    marginBottom: 16,
    overflowY: 'auto',
    paddingRight: 6,
    marginLeft: -8,
    '&::-webkit-scrollbar': {
      width: 6,
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
      borderRadius: '100px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgb(213, 213, 213)',
      borderRadius: '100px',
    },
  },
  productThumbnail: {
    padding: 8,
  },
  spinner: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export const CompleteLookContainer = (props) => {
  const { tagId, handleScrollView } = props;
  const classes = useStyle();
  const [tagGroup, setTagGroup] = useState({});
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (tagId) {
      let tagGroup = tagGroupsService.getTagGroupByTagId(tagId);
      setTagGroup(tagGroup);
    } else {
      setTagGroup(null);
    }
  }, [tagId]);

  useEffect(() => {
    const createProducts = async (tags) => {
      setIsLoading(true);
      let productList = [];
      for (let tagId of tags) {
        let tag = tagService.getTagById(tagId);
        let product = await productService.getProductById(tag.productId);
        let tmpProduct = { ...product, tagId: tagId, tagPosition: tag.position };
        productList.push(tmpProduct);
      }
      setProducts(productList);
      setIsLoading(false);
    };

    if (tagGroup && tagGroup.tags) {
      createProducts(tagGroup.tags);
    } else {
      setProducts([]);
    }
  }, [tagGroup]);

  const isLandscape = useWindowLandscape();

  return (
    <Grid item container style={{ height: '100%' }} direction="column" wrap="nowrap">
      <Grid item container alignItems="center">
        <Grid item xs>
          <span className={classes.title}>{`Complete`}</span>
          <span className={classes.subTitle}>{`look`}</span>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Box my={2}>
            <Divider />
          </Box>
        </Grid>
      </Grid>
      {products.length && !isLoading ? (
        <Grid container direction="row" className={classes.scrollBox}>
          {products.map((product) => (
            <Grid
              key={`thumbnail${product.id}`}
              item
              xs={12}
              sm={isLandscape ? 12 : 6}
              md={9}
              lg={6}
              xl={6}
              className={classes.productThumbnail}
            >
              <ProductThumbnail
                product={product}
                handleScrollView={handleScrollView}
              ></ProductThumbnail>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Grid container style={{ height: '100%', position: 'relative' }}>
          <Box className={classes.spinner}>
            <CircularProgress />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};
