import React, { useState } from 'react';

import { Box, Grid, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  productName: {
    marginTop: 4,
  },
}));

const ProductItem = (props) => {
  const { product } = props;
  const classes = useStyles();
  const [hovered, setHovered] = useState(false);

  const handlePointerOver = () => {
    setHovered(true);
  };

  const handlePointerOut = () => {
    setHovered(false);
  };

  const handleClick = (e, url) => {
    e.stopPropagation();
    window.open(url, '_blank', 'noreferrer');
  };

  return (
    <Grid item {...props}>
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          cursor: 'pointer',
        }}
        onPointerOver={handlePointerOver}
        onPointerOut={handlePointerOut}
        onClick={(e) => {
          handleClick(e, product.url);
        }}
      >
        <img src={hovered ? product.imageHoveredPreview : product.imagePreview} alt="hhh" />
        <Typography variant="caption" classes={{ root: classes.productName }} noWrap>
          {product.title}
        </Typography>
        <Typography variant="subtitle2">{`$${product.price}`}</Typography>
      </Box>
    </Grid>
  );
};

export default ProductItem;
