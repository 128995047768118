import useSpaceContext from '@hooks/useSpaceContext';
import clsx from 'clsx';
import React from 'react';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '50vh',
    position: 'absolute',
    top: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    pointerEvents: 'none',
  },
  logoContainer: {
    position: 'absolute',
    zIndex: 2002,
    maxHeight: 32,
    maxWidth: 200,
    width: '100%',
    height: '100%',
    transition: `all .5s ease-in-out`,
    pointerEvents: 'none',
  },
  imageLogo: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    filter: 'drop-shadow(0px 0px 1px rgba(255,255,255,0.8))',
  },
}));

export const StoreLogo = ({ animateLogo }) => {
  const classes = useStyles();
  const { spaceData } = useSpaceContext();

  const style = {
    before: {
      top: '100%',
      transform: `translate(0, -200%) scale(3)`,
    },
    start: {
      top: 5,
      transform: `translate(0, 50%) scale(1.75)`,
    },
  };

  return (
    <div className={classes.root}>
      <div className={classes.logoContainer} style={animateLogo ? style.start : style.before}>
        {spaceData && <img src={spaceData?.logo} alt="store-logo" className={classes.imageLogo} />}
      </div>
    </div>
  );
};
