import axios from 'axios';
import { Vector3 } from 'three';

class LocationApi {
  constructor() {
    this.host = process.env.REACT_APP_FIDELITY_API_URL;
    this.vec3 = new Vector3();
  }

  _convertToLocationPointData = (locationData) => {
    const temp = locationData.map((item) => {
      const position = this.vec3.clone();
      const rotation = this.vec3.clone();

      const location = {
        ...item,
        position: position.set(item.position.x, item.position.y, item.position.z),
        rotation: rotation.set(item.rotation.x, item.rotation.y, item.rotation.z),
      };

      return location;
    });

    return temp;
  };

  getEnabledLocationsBySpaceId = async (spaceId) => {
    try {
      const res = await axios(`${this.host}/api/v1/Location/GetEnabledLocationBySpaceId`, {
        params: { spaceId: spaceId },
      });
      if (res) {
        const locationPoints = this._convertToLocationPointData(res.data.data);
        return locationPoints;
      }
    } catch (error) {
      console.log('error', error);
    }
  };
}

export default new LocationApi();
