import React, { memo } from 'react';

import { Line } from '@react-three/drei';

function TagStem(props) {
  const { stemEndPosition } = props;
  return (
    <Line
      points={[[0, 0, 0], stemEndPosition]}
      color="#ecf0f1"
      lineWidth={1.5}
      transparent
      renderOrder={1}
      depthWrite={false}
    />
  );
}

export default memo(TagStem);
