import axios from 'axios';

class SpaceService {
  constructor() {
    this.host = process.env.REACT_APP_FIDELITY_API_URL;
  }

  getSpaceDetailsBySpaceAlias = async (alias) => {
    try {
      const res = await axios(`${this.host}/api/v1/Space/GetSpaceDetailsBySpaceAlias`, {
        params: { alias: alias },
      });
      if (res) {
        // console.log('Data', res.data.data);
        return res.data.data;
      }
    } catch (error) {
      console.log('error', error);
    }
  };
}

export default new SpaceService();
