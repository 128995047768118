const tags = [
  // {
  //   id: '1',
  //   type: 'link',
  //   position: { x: -5.074799002254409, y: 1.253248596920511, z: -5.674456585836051 },
  //   face: { x: -0.6363408334909036, y: 0.3592133756302246, z: 0.682668363409673 },
  //   media: {
  //     src:
  //       'https://factorie.com.au/half-half-oversized-t-shirt/5298430-08.html?dwvar_5298430-08_color=5298430-08&cgid=mens-tops&originalPid=5298430-08',
  //   },
  // },
  {
    id: '2',
    type: 'category',
    position: { x: -14.438824321352556, y: 0.5505583282411453, z: -6.521909224723199 },
    face: { x: 0.01718308679165291, y: 0.9989043153184313, z: 0.043530568185204455 },
    media: {
      src: 'https://www.google.com/',
    },
    category: "Women's Graphic Tees",
  },
  // {
  //   id: '3',
  //   type: 'link',
  //   position: { x: -6.799929168066011, y: 1.229014795661059, z: -4.082491373574272 },
  //   face: { x: 0.6762222522686112, y: 0.6092656967420871, z: -0.41414825402281474 },
  //   media: {
  //     src:
  //       'https://factorie.com.au/nba-bomber-jacket/5297762-09.html?dwvar_5297762-09_color=5297762-09&cgid=mens-tops&originalPid=5297762-09',
  //   },
  // },
  {
    id: '4',
    type: 'preview',
    position: { x: -12.803443068411054, y: 0.5294722238625691, z: -1.961055974810775 },
    face: { x: -0.05369789482405748, y: 0.986413451843319, z: 0.15525797278727102 },
    productSku: '5298003-03',
  },
  {
    id: '42',
    type: 'preview',
    position: { x: -8.2405481147167, y: 0.5754085628967959, z: -2.087178335652916 },
    face: { x: 0.011415881266743706, y: 0.9948095682131939, z: -0.10111182248571318 },
    productSku: '5298003-03',
  },
  {
    id: '5',
    type: 'preview',
    position: { x: -4.918380062308335, y: 1.0726538674980566, z: -0.16713812895904645 },
    face: { x: -0.44680488063172424, y: -0.07214171740171901, z: -0.8917179886342996 },
    productSku: '5298413-02',
  },
  {
    id: '6',
    type: 'category',
    position: { x: -12.552624604596499, y: 0.8518810837149033, z: -2.3889400186215095 },
    face: { x: 0.005540548916736498, y: 0.9939226591720924, z: 0.10994112015973755 },
    media: {
      src: 'https://www.google.com/',
    },
    category: "Women's Basic Tops & Tanks",
  },
  {
    id: '7',
    type: 'promotional',
    position: { x: -10.638839104627293, y: 1.4335928433521745, z: 0.8532138703604462 },
    face: { x: 0.19424666249191866, y: 0.3063378676785235, z: 0.9318934193012236 },
    promotionImage: '/assets/imgs/promotions/free-socks.jpg',
    description: '"Free Pair Of Socks" when you buy me',
  },
  {
    id: '8',
    type: 'promotional',
    position: { x: -2.09444408890459, y: 1.8447402022861283, z: -3.9184720920945106 },
    face: { x: -0.9956192998307953, y: 0.0634089600609322, z: 0.0687132708319718 },
    promotionImage: '/assets/imgs/promotions/30-Off-Fleece.jpg',
    description: '"30% Off Fleece"',
  },
  {
    id: '9',
    type: 'promotional',
    position: { x: -12.243295098930858, y: 2.135320273864102, z: -2.8481126257833904 },
    face: { x: 0.23939430550552432, y: 0.11493633172708931, z: 0.9640954341457324 },
    promotionImage: '/assets/imgs/promotions/free-sunglasses.jpg',
    description: '"Free Sunglasses when you buy this jacket"',
  },
  {
    id: '10',
    type: 'promotional',
    position: { x: -7.6033117229231, y: 0.8169439644583795, z: -8.052404034340835 },
    face: { x: 0.19370861749750093, y: 0.3434347899446095, z: 0.9189828706580492 },
    promotionImage: '/assets/imgs/promotions/2-for-10.jpg',
    description: '"2 for $10"',
  },
  {
    id: '11',
    type: 'link',
    position: { x: -13.056873024847295, y: 0.7870132053191502, z: -2.7288114518305115 },
    face: { x: 0.00936809815155619, y: 0.9999552582454726, z: 0.0013115808220486719 },
    media: {
      src: 'https://factorie.com.au/womens-basics/',
    },
  },
  {
    id: '12',
    type: 'link',
    position: { x: -12.093125122924686, y: 0.9035273750758288, z: -9.48700462293965 },
    face: { x: 0.014761054253252309, y: 0.9750646415078011, z: 0.2214295738120713 },
    media: {
      src: 'https://factorie.com.au/shorts-shop/',
    },
  },
  {
    id: '13',
    type: 'link',
    position: { x: -15.172525440315104, y: 1.3106848569148581, z: -2.189449690201169 },
    face: { x: 0.26442235760939314, y: 0.9564319022205962, z: 0.12376927409895472 },
    media: {
      src: 'https://factorie.com.au/womens-pants/',
    },
  },
  {
    id: '14',
    type: 'link',
    position: { x: -17.00280326976526, y: 1.076612538905304, z: -9.258113324521457 },
    face: { x: 0.9878883226318458, y: 0.02109956735501735, z: -0.15372530782232705 },
    media: {
      src: 'https://factorie.com.au/womens-jeans/',
    },
  },
  {
    id: '15',
    type: 'link',
    position: { x: -3.854848797413471, y: 1.2313676266780915, z: -11.982486640483952 },
    face: { x: -0.2460209512577317, y: 0.8188526431526846, z: 0.5186077904776432 },
    media: {
      src: 'https://factorie.com.au/mens-graphictees/',
    },
  },
  {
    id: '16',
    type: 'link',
    position: { x: -4.2546584296730785, y: 0.9107674210873425, z: -9.416519742266562 },
    face: { x: -0.16005569521552618, y: 0.9675941873381113, z: 0.19530402724617435 },
    media: {
      src: 'https://factorie.com.au/shorts-shop/',
    },
  },
  {
    id: '17',
    type: 'link',
    position: { x: -4.332188647885789, y: 1.046391142823496, z: -0.12008628525143594 },
    face: { x: 0.02788825820348783, y: 0.04017006710716626, z: -0.9988035896826669 },
    media: {
      src: 'https://factorie.com.au/mens-pants/',
    },
  },
  {
    id: '18',
    type: 'link',
    position: { x: -7.60706560615482, y: 1.1951273469472805, z: -8.123837934674292 },
    face: { x: -0.06861232317010894, y: 0.20256057941496225, z: 0.9768631228459161 },
    media: {
      src: 'https://factorie.com.au/accessories-socks/',
    },
  },
];

export default tags;
