import spaceApi from '@api/api.space';
import DataStorage from '@apis/data/DataStorage';
import storesService from '@apis/services/stores.service';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

const SpaceContext = createContext({
  spaceId: '',
  setSpaceId: (spaceId) => {},
  spaceData: {},
  spaceDBId: '',
  initializedDataStorage: false,
});

export const SpaceContextProvider = ({ children }) => {
  const [currentSpaceId, setCurrentSpaceId] = useState('');
  const [spaceDBId, setSpaceDBId] = useState('');
  // const spaceData = useMemo(() => storesService.getStore(), [
  //   currentSpaceId,
  //   initializedDataStorage,
  // ]);
  const [spaceData, setSpaceData] = useState();
  const [initializedDataStorage, setInitializedDataStorage] = useState(false);

  const getSpaceDetails = async (alias) => {
    const res = await spaceApi.getSpaceDetailsBySpaceAlias(alias);
    await DataStorage.initialize(alias, res.id);
    const spaceData = storesService.getStore();

    setSpaceData(spaceData);
    setSpaceDBId(res.id);
    setInitializedDataStorage(true);
  };

  useEffect(() => {
    if (currentSpaceId) {
      getSpaceDetails(currentSpaceId);
    }
  }, [currentSpaceId]);

  const setSpaceId = useCallback(
    async (spaceId) => {
      // await DataStorage.initialize(spaceId);
      setCurrentSpaceId(spaceId);
    },
    [currentSpaceId]
  );

  let contextValue = {
    spaceId: currentSpaceId,
    setSpaceId,
    spaceData: spaceData,
    spaceDBId: spaceDBId,
    initializedDataStorage: initializedDataStorage,
  };

  return <SpaceContext.Provider value={contextValue}>{children}</SpaceContext.Provider>;
};

const useSpaceContext = () => {
  const context = useContext(SpaceContext);

  if (context === undefined) {
    throw new Error('useSpaceContext must be used within SpaceContext');
  }

  return context;
};

export default useSpaceContext;
