import React from 'react';

import { Box, Typography } from '@material-ui/core';

const PromotionModalBody = (props) => {
  const { promotionImage, description } = props;
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: 240,
      }}
    >
      <img src={promotionImage} alt="promotion" />
      <Typography align="center" style={{ marginTop: 8 }}>
        {description}
      </Typography>
    </Box>
  );
};

export default PromotionModalBody;
