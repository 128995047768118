import React from 'react';

import { Box, Divider, Grid, makeStyles } from '@material-ui/core';

import { ItemGroup } from './ItemGroup';

const useStyles = makeStyles((theme) => ({
  productSeries: {
    fontSize: 24,
    fontWeight: 700,
    marginRight: 12,
  },
  productName: {
    fontSize: 24,
  },
  imageBox: {
    height: 280,
    width: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
  },
  scrollBox: {
    flexDirection: 'column',
    overflowY: 'auto',
    flexWrap: 'nowrap',
    paddingRight: 14,
    '&::-webkit-scrollbar': {
      width: 6,
    },
    // '&:hover': {
    //   paddingRight: 8,
    //   overflowY: 'auto',
    // },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
      borderRadius: '100px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgb(213, 213, 213)',
      borderRadius: '100px',
    },
  },
}));

export const ProductDetails = (props) => {
  const classes = useStyles();
  const { product } = props;

  return (
    <Grid item container style={{ height: '100%' }} direction="column" wrap="nowrap">
      <Grid item container alignItems="center">
        <Grid item xs>
          <span className={classes.productSeries}>{product.seriesName}</span>
          <span className={classes.productName}>{product.name}</span>
        </Grid>
      </Grid>
      {/* <Grid item container alignItems="center">
        <Grid item>
          <span>Review :</span>
        </Grid>
        <Grid item xs>
          <StarRating name="product-ratting" value={3} color={'#ffb400'} />
        </Grid>
      </Grid> */}
      <Grid item container>
        <Grid item xs={12}>
          <Box my={2}>
            <Divider />
          </Box>
        </Grid>
      </Grid>
      <Grid item container className={classes.scrollBox}>
        {product.itemGroups &&
          product.itemGroups.map((itemGroup, index) => (
            <ItemGroup key={`itemGroup${index}`} itemGroup={itemGroup} />
          ))}
        {/* <ProductImage product={product} /> */}
        {/* <ProductColor /> */}
        {product.imagePath && (
          <Grid item container>
            <Box
              className={classes.imageBox}
              style={{ backgroundImage: `url(${product.imagePath})` }}
            ></Box>
          </Grid>
        )}
        <Grid item container>
          <Grid item xs>
            <p>{product.description}</p>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
