import useSpaceContext from '@hooks/useSpaceContext';
import resourceManager from '@utils/resource.manager';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';

import apiLocation from '../apis/api.location';

const LocationsContext = createContext({
  locationList: [],
  getLocationByKey: () => {},
});

export const LocationsContextProvider = ({ children }) => {
  const { spaceId, spaceDBId } = useSpaceContext();
  const [locations, setLocations] = useState();

  const performBackgroundCachingResources = async (spaceId, locations) => {
    const splashLocationImages = locations.map((x) => x.imageUrl);
    await resourceManager.cacheAllResource(spaceId, splashLocationImages);
  };

  useEffect(() => {
    const getLocationList = async () => {
      const locationPoints = await apiLocation.getEnabledLocationsBySpaceId(spaceDBId);
      console.log('res', locationPoints);
      setLocations(locationPoints);
      performBackgroundCachingResources(spaceId, locationPoints);
    };

    if (spaceId && spaceDBId) {
      getLocationList();
    }
  }, [spaceId, spaceDBId]);

  const getLocationByKey = useCallback(
    (locationkey) => {
      console.log('locations', locations);
      const location = locations.find((x) => x.locationKey == locationkey);
      return location;
    },
    [locations]
  );

  const contextValue = {
    locationList: locations,
    getLocationByKey: getLocationByKey,
  };

  return <LocationsContext.Provider value={contextValue}>{children}</LocationsContext.Provider>;
};

const useLocations = () => {
  const context = useContext(LocationsContext);

  if (context === undefined) {
    throw new Error('useLocations must be used within LocationsContext');
  }

  return context;
};

export default useLocations;
