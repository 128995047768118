import { useBreakpoint } from '@hooks/useBreakpoint';
import React from 'react';

import { Box, makeStyles } from '@material-ui/core';

import { DownloadButton } from './DownloadButton';
import { EntranceButton } from './EntranceButton';
import { BackgroundMusic } from './background-music';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'absolute',
    bottom: 40,
    left: 40,
    display: 'flex',
    alignItems: 'center',
    transformOrigin: 'left',
    transition: `all .2s ease-in-out`,
  },
}));

export const BottomControls = () => {
  const classes = useStyles();
  const breakpoint = useBreakpoint();

  const scale = {
    xs: {
      transform: `scale(0.7)`,
      left: 20,
      bottom: 20,
    },
    normal: {
      transform: `scale(1)`,
      left: 40,
      bottom: 40,
    },
  };

  return (
    <Box className={classes.container} style={breakpoint == 'xs' ? scale.xs : scale.normal}>
      <EntranceButton />
      {/* <DownloadButton /> */}
      <BackgroundMusic />
    </Box>
  );
};
