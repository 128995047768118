import useModal from '@features/layout/modal/hooks/useModal';
import ProductPreviewModalBody from '@features/products/components/ProductPreviewModalBody';
import { animated, config, useSpring } from '@react-spring/three';
import React, { memo, useCallback, useEffect, useState } from 'react';

import { Billboard, Text, useCursor } from '@react-three/drei';

import useTag from '../hooks/useTag';
import useTagsStatus from '../hooks/useTagsStatus';
import TagStem from './TagStem';

const ProductTag = (props) => {
  const { tagData, stemEndPosition } = props;
  const [hovered, setHovered] = useState(false);
  const [focus, setFocus] = useState(true);
  useCursor(hovered);
  const { open } = useModal();
  const { handleTagClick } = useTag();
  const { currentTagId } = useTagsStatus();

  const { rotation, borderScale, dotScale } = useSpring({
    scale: hovered ? 1 : 0,
    rotation: hovered ? [0, 0, Math.PI / 8] : [0, 0, 0],
    borderScale: focus ? 1 : 0.7,
    dotScale: focus ? 1.25 : 0.55,
    config: config.gentle,
  });

  const handlePointerOver = () => {
    setHovered(true);
  };

  const handlePointerOut = () => {
    setHovered(false);
  };

  const handleClick = useCallback(
    (e, tag) => {
      e.stopPropagation();
      handleOpenProductModal(tag);
    },
    [tagData]
  );

  const handleOpenProductModal = useCallback(
    (tag) => {
      const modalBody = <ProductPreviewModalBody productSku={tag.productSku} />;
      handleTagClick(tag, () => {
        open(modalBody);
      });
    },
    [tagData]
  );

  useEffect(() => {
    if (tagData.id === currentTagId) {
      handleOpenProductModal(tagData);
    }
  }, []);

  return (
    <group position={[0, 0, 0]}>
      <TagStem stemEndPosition={stemEndPosition}></TagStem>
      <group
        position={stemEndPosition}
        scale={0.065}
        onClick={(e) => {
          handleClick(e, tagData);
        }}
        onPointerOver={handlePointerOver}
        onPointerOut={handlePointerOut}
      >
        <Billboard follow={true} position={[0, 0, 0]}>
          <animated.group rotation={rotation} renderOrder={3}>
            <Text
              font={
                'https://fonts.gstatic.com/s/materialicons/v70/flUhRq6tzZclQEJ-Vdg-IuiaDsNa.woff'
              }
              text={'assignment'}
              fontSize={0.95}
              anchorX="center"
              anchorY="middle"
              color={'#fff'}
              renderOrder={3}
            />
          </animated.group>
          <animated.mesh renderOrder={2} scale={dotScale}>
            <circleGeometry args={[0.65, 32]} />
            <meshStandardMaterial
              color={'#ee1f3b'}
              depthWrite={true}
              transparent
              opacity={1}
              alphaToCoverage
            />
          </animated.mesh>
          <animated.mesh renderOrder={1} scale={borderScale}>
            <circleGeometry args={[1, 32]} />
            <meshStandardMaterial
              color={'#fff'}
              depthWrite={true}
              transparent
              opacity={1}
              alphaToCoverage
            />
          </animated.mesh>
        </Billboard>
      </group>
    </group>
  );
};

export default memo(ProductTag);
