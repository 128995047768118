import tagGroupsRepository from '@apis/repositories/tag-groups.repository';

class TagGroupService {
  constructor() {}

  getTagGroupByTagId = (tagId) => {
    let tagGroups = tagGroupsRepository.getTagGroups();
    let tagGroup = tagGroups.find((tg) => tg.tags.includes(tagId));
    return tagGroup;
  };
}

export default new TagGroupService();
