import useLocations from '@features/3d-Space/locations/hooks/useLocations';
import { useBreakpoint } from '@hooks/useBreakpoint';
import useSpaceContext from '@hooks/useSpaceContext';
import navigationManager from '@utils/navigation.manager';
import clsx from 'clsx';
import React, { useCallback, useLayoutEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';

const useStyles = makeStyles((theme) => ({
  buttonWrapper: {
    margin: '0px 4px',
    maxWidth: 48,
    background: 'rgba(0, 0, 0, 0.7)',
    borderRadius: 100,
    overflowX: 'hidden',
    transition: `max-width 0.5s`,
    '&:hover': {
      cursor: 'pointer',
      maxWidth: '115px',
      '& span': {
        opacity: 1,
      },
    },
  },
  buttonBox: {
    color: '#fff',
    padding: '8px 16px',
    display: 'inline-flex',
    alignItems: 'center',
    transformOrigin: 'left',
    '& .MuiSvgIcon-root': {
      width: 16,
      height: 16,
    },
  },
  buttonText: {
    color: '#fff',
    marginLeft: 8,
    lineHeight: 1,
    fontSize: 12,
    textTransform: 'uppercase',
    transition: `opacity 0.5s`,
    opacity: 0,
  },
  focusButton: {
    maxWidth: '115px',
    '& span': {
      opacity: 1,
    },
  },
}));

export const EntranceButton = () => {
  const classes = useStyles();
  const [actived, setActived] = useState(false);
  const breakpoint = useBreakpoint();
  const { spaceData } = useSpaceContext();
  const { getLocationByKey } = useLocations();

  const checkSmallScreen = (breakpoint) => {
    switch (breakpoint) {
      case 'xl':
        return false;
      case 'lg':
        return false;
      case 'md':
        return true;
      case 'sm':
        return true;
      case 'xs':
        return true;
      default:
        return false;
    }
  };
  useLayoutEffect(() => {
    if (checkSmallScreen(breakpoint)) {
      setActived(true);
    } else {
      setActived(false);
    }
  }, [breakpoint]);

  const handleClick = () => {
    if (spaceData?.storeFront) {
      let location = getLocationByKey(spaceData.storeFront);
      console.log('Find locaton', location);
      const entranceMenu = spaceData.menus.find((x) => x.locationId == spaceData.storeFront);
      if (location) {
        navigationManager.requestNewLocation(location, false, entranceMenu?.options);
      }
    }
  };

  return (
    <div
      className={clsx(classes.buttonWrapper, actived ? classes.focusButton : '')}
      onClick={handleClick}
    >
      <div className={classes.buttonBox}>
        <HomeOutlinedIcon />
        <span className={classes.buttonText}>Entrance</span>
      </div>
    </div>
  );
};
