import { myEmitter } from '../seed-works/myEmitter';

class ZoomObserver {
  constructor() {
    this.currentZoomLevel = 0;
  }

  updateZoomLevel = (zoomLevel) => {
    if (this.currentZoomLevel != zoomLevel) {
      this.currentZoomLevel = zoomLevel;
      myEmitter.emit(ZoomObserverEventName.UpdateZoomLevel, zoomLevel);
    }
  };

  onZoomLevelUpdated = (onZoomLevelUpdated) => {
    if (onZoomLevelUpdated) {
      myEmitter.on(ZoomObserverEventName.UpdateZoomLevel, onZoomLevelUpdated);
    }
  };
  offZoomLevelUpdated = (onZoomLevelUpdated) => {
    if (onZoomLevelUpdated) {
      myEmitter.off(ZoomObserverEventName.UpdateZoomLevel, onZoomLevelUpdated);
    }
  };
}

const ZoomObserverEventName = {
  UpdateZoomLevel: 'ZOUpdateZoomLevel',
};

export default new ZoomObserver();
