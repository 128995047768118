import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';

const BackgroundMusicContext = createContext({
  isPlaying: false,
  pauseBackgroundMusic: () => {},
  playBackgroundMusic: () => {},
  toggleBackgroundMusic: () => {},
});

export const BackgroundMusicProvider = ({ children }) => {
  const [isPlaying, setIsPlaying] = useState(false);

  const pauseBackgroundMusic = useCallback(() => {
    setIsPlaying(false);
  }, [isPlaying]);

  const playBackgroundMusic = useCallback(() => {
    setIsPlaying(true);
  }, [isPlaying]);

  const toggleBackgroundMusic = useCallback(() => {
    setIsPlaying((state) => !state);
  }, [isPlaying]);

  let contextValue = {
    isPlaying: isPlaying,
    pauseBackgroundMusic,
    playBackgroundMusic,
    toggleBackgroundMusic,
  };

  return (
    <BackgroundMusicContext.Provider value={contextValue}>
      {children}
    </BackgroundMusicContext.Provider>
  );
};

const useBackgroundMusic = () => {
  const context = useContext(BackgroundMusicContext);

  if (context === undefined) {
    throw new Error('useBackgroundMusic must be used within BackgroundMusicContext');
  }

  return context;
};

export default useBackgroundMusic;
