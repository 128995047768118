const store = {
  id: 'cotton-on-westfield-geelong',
  name: 'COTTON ON - Westfield Geelong',
  model:
    'https://fidelitystorageprod-cdn.azureedge.net/cottonon-vr-store-3d/cotton-on-westfield-geelong/store-models/ct-wg-D.glb',
  modelId: 'd586bb9c9b3041bdba6554615f38ec83',
  storeFrontId: '01653eec74af4665aa83ccef92ae88c5',
  storeLogo:
    'https://fidelitystorageprod-cdn.azureedge.net/cottonon-vr-store-3d/cotton-on-westfield-geelong/cotton-on-logo.svg',
  storeWelcomeText: 'Welcome to the Cotton On Experience.',
  storeFavIcon:
    'https://fidelitystorageprod-cdn.azureedge.net/cottonon-vr-store-3d/cotton-on-westfield-geelong/cotton-on.ico',

  cameraRotation: { azimuthal: -90, polar: 90 },
  rotationDegrees: 178,
  navigationMenu: [
    {
      title: 'Entrance',
      locationId: '01653eec74af4665aa83ccef92ae88c5',
      options: {
        rotation: { azimuthal: -1.6222239240463827, polar: 1.5707963267948966 },
      },
    },
  ],
  panoImageBaseUrl:
    'https://fidelitystorageprod-cdn.azureedge.net/cottonon-vr-store-3d/cotton-on-westfield-geelong/location-images-equi/',
  panoImageFormat: '.webp',
  tiledImageSettings: {
    baseUrl512:
      'https://fidelitystorageprod-cdn.azureedge.net/cottonon-vr-store-3d/cotton-on-westfield-geelong/ocation-images-cube-512/',
    baseUrl4k:
      'https://fidelitystorageprod-cdn.azureedge.net/cottonon-vr-store-3d/cotton-on-westfield-geelong/location-images-tiled-4k/',
    baseUrl2k:
      'https://fidelitystorageprod-cdn.azureedge.net/cottonon-vr-store-3d/cotton-on-westfield-geelong/location-images-tiled-2k/',
  },
  layoutSettings: {
    modalAlignment: 'right',
  },
};

export default store;
