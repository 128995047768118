import * as THREE from 'three';

class GeometryHelper {
  createPlaneGeometry = (min, max) => {
    let planeGeom = new THREE.BufferGeometry();
    let uv;
    var v00 = [0, 0];
    var v01 = [0, 1];
    var v10 = [1, 0];
    var v11 = [1, 1];
    let isYAxis = false;

    if (min.x == max.x) {
      if (min.x > 0) {
        uv = [v11, v01, v10, v00].flat();
      } else {
        uv = [v01, v11, v00, v10].flat();
      }
    } else if (min.z == max.z) {
      if (min.z > 0) {
        uv = [v01, v11, v00, v10].flat();
      } else {
        uv = [v11, v01, v10, v00].flat();
      }
    } else if (min.y == max.y) {
      isYAxis = true;
      if (min.y > 0) {
        uv = [v00, v10, v01, v11].flat();
      } else {
        uv = [v01, v11, v00, v10].flat();
      }
    }
    planeGeom.setAttribute('uv', new THREE.BufferAttribute(new Float32Array(uv), 2));

    if (isYAxis) {
      planeGeom.setAttribute(
        'position',
        new THREE.BufferAttribute(
          new Float32Array([
            min.x,
            max.y,
            max.z,
            ///
            max.x,
            max.y,
            max.z,
            ///
            min.x,
            min.y,
            min.z,
            ///
            max.x,
            min.y,
            min.z,
          ]),
          3
        )
      );
    } else {
      planeGeom.setAttribute(
        'position',
        new THREE.BufferAttribute(
          new Float32Array([
            min.x,
            max.y,
            min.z,
            ///
            max.x,
            max.y,
            max.z,
            ///
            min.x,
            min.y,
            min.z,
            ///
            max.x,
            min.y,
            max.z,
          ]),
          3
        )
      );
    }

    planeGeom.setIndex([0, 2, 1, 2, 3, 1]);

    return planeGeom;
  };
}
export default new GeometryHelper();
