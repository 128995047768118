import storesRepository from '@apis/repositories/stores.repository';

class StoresService {
  constructor() {}

  getStore = (storeId) => {
    return storesRepository.getStore(storeId);
  };
}

export default new StoresService();
